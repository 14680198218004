import { ForEach } from "../helpers/dom";
const iconsLinks = document.querySelectorAll(".iconRow__icon");

if (iconsLinks && iconsLinks.length) {
  let pageTitle: any;
  let pageUrl: any;
  let windowOptions: any;
  let querystringChar = "?";
  pageTitle = encodeURIComponent(document.title.replace("|", ""));

  var width = 1024;
  var height = 768;
  var leftOffset = window.top.outerWidth / 2 + window.top.screenX - width / 2;
  var topOffset = window.top.outerHeight / 2 + window.top.screenY - height / 2;
  windowOptions =
  "location=yes,scrollbars=yes,status=yes,width=" +
    width +
    ",height=" +
    height +
    ",left=" +
    leftOffset +
    ",top=" +
    topOffset;
  ForEach(iconsLinks, function (icon, idx) {
    icon.addEventListener("click", function (e) {
      e.preventDefault();
      let target = e.target as HTMLElement;

      if (target.classList.contains("iconRow__icon-linkedin")) {
        _shareOnLinkedIn(target.dataset.prefix, target.dataset.url);
      } else if (target.classList.contains("iconRow__icon-facebook")) {
        _shareOnFacebook(target.dataset.prefix, target.dataset.url);
      } else if (target.classList.contains("iconRow__icon-twitter")) {
        _shareOnTwitter(target.dataset.prefix, target.dataset.url);
      } else if (target.classList.contains("iconRow__icon-email")) {
        _shareOnEmail(target.dataset.prefix, target.dataset.url);
      } else if (target.classList.contains("iconRow__icon-print")) {
        _printSite();
      }
    });
  });
  let _shareOnLinkedIn = function (prefix: string, url: string) {
    if (url.indexOf("?") !== -1) {
      querystringChar = "&";
    }
    var encodedUri = encodeURIComponent(
      decodeURI(url) +
        querystringChar +
        "utm_source=linkedin-share&utm_medium=social"
    );
    var windowUrl = prefix + encodedUri;
    window.open(windowUrl, "_blank", windowOptions);
  };

  let _shareOnFacebook = function (prefix: string, url: string) {
    if (url.indexOf("?") !== -1) {
      querystringChar = "&";
    }
    var encodedUri = encodeURIComponent(
      decodeURI(url) +
        querystringChar +
        "utm_source=facebook-share&utm_medium=social"
    );
    var windowUrl = prefix + encodedUri;
    window.open(windowUrl, "_blank", windowOptions);
  };
  let _shareOnTwitter = function (prefix: string, url: string) {
    if (url.indexOf("?") !== -1) {
      querystringChar = "&";
    }
    var encodedUri = encodeURIComponent(
      decodeURI(url) +
        querystringChar +
        "utm_source=twitter-share&utm_medium=social"
    );
    var windowUrl = prefix + encodedUri;
    window.open(windowUrl, "_blank", windowOptions);
  };

  let _shareOnEmail = function (prefix: string, url: string) {
    var encodedUri =
      url + querystringChar + "utm_source=email-share&utm_medium=email";
    window.open(
      "mailto:?subject=" + pageTitle + "&body=" + encodedUri,
      "_self"
    );
  };

  let _printSite = function () {
    let printContent = document.querySelector<HTMLElement>(".print-content");
    if (printContent) {
      window.print();
    }
  };
}

import {LazyLoad} from "./intersectionObserver";
const allPostionsContainer = document.querySelector<HTMLDivElement>(".all__positions");
export const renderAllPositions = (jobs) => {
    let filteredData = jobs
    let template = "";
    allPostionsContainer.innerHTML = "";
    if (filteredData.length >= 1) {
        for (let job of filteredData) {
            template += `
               <div class="col-lg-4 col-sm-6 card-text-button__container" style="visibility:hidden">
                    <div class="card-text-button__subtitle">
                        <span>${job.Location ? job.Location : ""}</span>
                    </div>
                    <div class="card-text-button__title">
                        <h4>${job.Heading ? job.Heading : ""}</h4>
                    </div>
                    <div class="card-text-button__text">
                        <p>
                        ${job.Preamble ? job.Preamble : ""}
                        </p>
                    </div>
                    <div class="card-text-button__button">
                        <div class="btn-container">
                            <a class="btn-default btn-default-thin btn-backgroundcolor-red" href='${job.JobUrl ? job.JobUrl : ""}'>
                            ${job.LinkText ? job.LinkText : ""}
                            </a>
                        </div>
                    </div>
                </div>
               `;
        }
        allPostionsContainer.insertAdjacentHTML('beforeend', template);
        const items = document.querySelectorAll(".card-text-button__container");
        LazyLoad(items);
    } else {
        let noResult = allPostionsContainer.dataset;
        template = `
            <div class="col-lg-12">
                ${noResult}
            </div>
            `
        allPostionsContainer.insertAdjacentHTML('beforeend', template);
    }
}

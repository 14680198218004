const listAndImageContainers = <NodeListOf<HTMLDivElement>>document.querySelectorAll(".imagewithcolumns");
const imageContainer = <NodeListOf<HTMLImageElement>>document.querySelectorAll(".imagewithcolumns__image");
import { ForEach } from "../helpers/dom";
if (imageContainer && imageContainer.length) {
    renderPictures();
    let doit;
    window.onresize = function () {
        clearTimeout(doit);
        doit = setTimeout(renderPictures, 100);
    };
}
function renderPictures() {
    ForEach(listAndImageContainers, function (listAndImageContainer, index) {
        let dataContainer: any = listAndImageContainer.children[0].children[0].children[0];
        let mobileImage = dataContainer.dataset.mobileSrc;
        let image = dataContainer.dataset.imageSrc;
        let imageElement :any = imageContainer[index].children[0];
        resizedw()
        function resizedw() {
            if ($(window).width() <= 767) {
                if (mobileImage) {
                    imageElement.src = mobileImage
                }
            } else {
                if (image) {
                    imageElement.src = image
                }
            }
        }
    })
}

export const LazyLoad = (elements) => {
    let options = {
        root: null,
        rootMargin: "100px",
        threshold: 0.5
    };
    let observer = new IntersectionObserver(
        function (entries, observer) {
            handleIntersect(entries, observer);
        },
        options);
    elements.forEach((targetElement) => {
        observer.observe(targetElement);
    });
    const handleIntersect = function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let imageLink = entry.target.children[0].children[0].children[0]
                if (imageLink != undefined) {
                    let image = entry.target.children[0].children[0].children[0]
                    image.src = imageLink.dataset.link;
                }
                entry.target.style.visibility = "visible"
                observer.unobserve(entry.target);
            }
        });
    };
}

export const LazyLoadEvents = (elements) => {
    let options = {
        root: null,
        rootMargin: "100px",
        threshold: 0.5
    };
    let observer = new IntersectionObserver(
        function (entries, observer) {
            handleIntersect(entries, observer);
        },
        options);
    elements.forEach((targetElement) => {
        observer.observe(targetElement);
    });
    const handleIntersect = function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let imageLink = entry.target.children[0].children[0].children[0].children[0].dataset.link;
                if (imageLink) {
                    let image = entry.target.children[0].children[0].children[0].children[0]
                    image.src = imageLink
                }
                entry.target.style.visibility = "visible"
                observer.unobserve(entry.target);
            }
        });
    };
}

export const LazyLoadImages = (elements) => {
    
    let options = {
        root: null,
        rootMargin: "400px",
        threshold: 0.5
    };
    let observer = new IntersectionObserver(
        function (entries, observer) {
            handleIntersect(entries, observer);
        },
        options);
    elements.forEach((targetElement) => {
        observer.observe(targetElement);
    });
    const handleIntersect = function (entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                let imageLink = entry.target;
                if (imageLink != undefined) {
                    imageLink.style.animation = "nolatoFadeIn 1s ease"
                    imageLink.src = imageLink.dataset.lasyload;
                }
                observer.unobserve(entry.target);
            }
        });
    };
}
const bodyContainer = document.querySelector("body");
const closeOverlay = document.createElement("div");
closeOverlay.classList.add("nolato__close-overlay");
bodyContainer.appendChild(closeOverlay);

const Helper = {
  overlay: closeOverlay,
};

export default Helper;

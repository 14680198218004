const iconRows = document.querySelectorAll<HTMLElement>(".iconRow");
import { ForEach, width } from "../helpers/dom";
if (iconRows && iconRows.length) {
  ForEach(iconRows, function (iconRow :HTMLElement, idx) {
    reportWindowSize();
    function reportWindowSize() {
      {
        if (width <= 992) {
          iconRow.classList.remove("flex-column");
          iconRow.style.marginLeft = "40px";
          iconRow.style.marginBottom = "40px";
        } else {
          iconRow.classList.add("flex-column");
          iconRow.style.marginLeft = "0px";
          iconRow.style.marginBottom = "0px";
        }
      }
    }
    window.onresize = reportWindowSize;
  });
}

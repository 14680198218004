import Helper from "../components/helper";
const axios = require('axios').default;

export const ForEach = (arr: NodeListOf<Element>, callback: (element: Element, index: number) => void) => {
  const l: number = arr.length;
  for (let i: number = 0; i < l; i++) {
    callback(arr[i], i);
  }
};

export const scrollToElement = (element: HTMLElement, duration: number, offset: number = 200) => {
  $('html, body').animate({
    scrollTop: $(element).offset().top -offset
  }, duration);
}

export let width = (window.innerWidth > 0) ? window.innerWidth : screen.width;

export const SlideDown = (element: HTMLElement, duration) => {
  $(element).slideDown(duration, function () { });
}
export const SlideUp = (element: HTMLElement, duration) => {
  $(element).slideUp(duration, function () { });
}

export const ContactModal = () => {
  let overlay = Helper.overlay;
  const modalContainer = document.querySelector<HTMLDivElement>(".contact-modal__popup");
  const closebutton = document.querySelector(".contact-modal__close");
  openContactModal();

  overlay.addEventListener("click", function () {
    closeContactModal()
  })

  closebutton.addEventListener("click", function () {
    closeContactModal()
  })

  function closeContactModal() {
    modalContainer.style.transform = "translateX(100%)";
    overlay.classList.remove("nolato__overlay");
    overlay.classList.remove("nolato__active");
  }

  function openContactModal() {
    $('.contact-modal form').show();
    $('.error-message').hide();
    $('.thank-you-message').hide();
    modalContainer.style.transform = "translateX(0%)";
    overlay.classList.add("nolato__overlay");
    overlay.classList.add("nolato__active");
  }
}
export const iframeFlyout = () => {
  let overlay = Helper.overlay;
  const IframeContainer = document.querySelector<HTMLDivElement>(".iframe-Flyout");
  const closebutton = document.querySelector(".iframe-Flyout__close");
  openIframeModal();

  overlay.addEventListener("click", function () {
    closeIframeModal()
  })

  closebutton.addEventListener("click", function () {
    closeIframeModal()
  })

  function closeIframeModal() {
    IframeContainer.style.transform = "translateX(100%)";
    overlay.classList.remove("nolato__overlay");
    overlay.classList.remove("nolato__active");
  }

  function openIframeModal() {
    IframeContainer.style.transform = "translateX(0%)";
    overlay.classList.add("nolato__overlay");
    overlay.classList.add("nolato__active");
  }
}
export const getData = async (url) => {
  let hostname = getHostname();
  let requestString = `${hostname}${url}`
  return await axios.get(requestString)
    .then(response => {
      return response.data;
    })
    .catch(error => {
      console.log(error, "error response");
      return error;
    })
}

function getHostname() {
  let hostname = window.location.hostname + "//api/sitecore/";
  let port = `${window.location.port}`;
  var url = window.location.pathname.split('/');
  var cultureid = url[1];
  if (cultureid == "products") {
    cultureid = "en";
  }
  if (hostname == 'localhost' || hostname == 'nolatosc.dev.local') {
    if (port) {
      port = '';
    }
    hostname = 'nolatosc.dev.local//api/sitecore/'
  }
  try {
    return `https://${hostname}${port}`;

  } catch (error) {
    alert(`Sorry, we couldn't fetch available products because servers arent responding. Please try again later`);
  }
}

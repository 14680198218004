import { ForEach, SlideDown, SlideUp } from "../helpers/dom";
import Helper from "./helper";
const navbarButtonsWithDropBar = document.querySelectorAll(
  ".nav__navbar-hasdropdown"
);
const mobilnNavbarButtonsWithDropBar = document.querySelectorAll(
  ".mobil__sidebar-hasdropdown"
)
const dropdownCloseButton = document.querySelectorAll(
  ".nav__dropdown-close-section"
);
const dropwdownContainers = document.querySelectorAll(".nav__navbar-dropdown");
const mobileDropdown = document.querySelectorAll<HTMLElement>(".mobil__sidebar__dropdown");
const closeOverlay = Helper.overlay;
let indexCheck = null;
let dropdown = null;
let doitNavbar;

window.addEventListener('resize', function () {
  clearTimeout(doitNavbar);
  doitNavbar = setTimeout(setNavbar, 100);
});
setNavbar();

function setNavbar() {
  if ($(window).width() > 1025) {
    ForEach(navbarButtonsWithDropBar, function (element, idx) {
      let timeout;
      $(element).on('mouseenter', function () {
        if (timeout != null) { clearTimeout(timeout); }
        timeout = setTimeout(function () {
          if (dropwdownContainers[idx] === undefined || indexCheck === idx) return;
          let scroll_position = 0;
          let scroll_direction;
          indexCheck = idx;
          closeDropdown();
          dropdown = dropwdownContainers[idx];
          closeOverlay.classList.add("nolato__active");
          SlideDown(dropdown, "fast");
          dropdownCloseButton[idx].addEventListener("click", function () {
            indexCheck = null
            closeDropdown();
          })
          window.addEventListener('scroll', closeDropdownOnScrollUp);
          function closeDropdownOnScrollUp() {
            scroll_direction = (document.body.getBoundingClientRect()).top > scroll_position ? 'up' : 'down';
            scroll_position = (document.body.getBoundingClientRect()).top;
            if (scroll_direction === "up") {
              indexCheck = null
              closeDropdown();
              window.removeEventListener("scroll", closeDropdownOnScrollUp)
            }
          }
        }, 300);
      });

      $(element).on('mouseleave', function () {
        if (timeout != null) {
          clearTimeout(timeout);
          timeout = null;
        }
      });
    });
  } else {
    const hamburgerButton = document.querySelector(".menu-button");
    if (hamburgerButton) {
      const navbarContainer = document.querySelector(".mobil__sidebar");
      const hasdropdown = document.querySelectorAll(".mobil__sidebar-hasdropdown");
      let toogle = true;
      hamburgerButton.addEventListener("click", function () {
        if (toogle) {
          openNavbar();
        } else {
          closeNavbar();
        }
        closeOverlay.addEventListener("click", function () {
          closeNavbar();
        });

        function openNavbar() {
          $("body").css("overflow", "hidden");
          hamburgerButton.classList.add("toggle");
          navbarContainer.classList.add("mobil__sidebar--active");
          closeOverlay.classList.add("nolato__overlay-color");
          closeOverlay.classList.add("nolato__active");
          toogle = !toogle;
        }

        function closeNavbar() {
          $("body").css("overflow", "auto");
          hamburgerButton.classList.remove("toggle");
          navbarContainer.classList.remove("mobil__sidebar--active");
          closeOverlay.classList.remove("nolato__overlay-color");
          closeOverlay.classList.remove("nolato__active");
          toogle = !toogle;
        }
        ForEach(mobilnNavbarButtonsWithDropBar, function (element, idx) {
          let toggleDropdown = true;
          element.addEventListener("click", function (e) {
            if (toggleDropdown) {
              SlideDown(mobileDropdown[idx], "fast");
              hasdropdown[idx].classList.add("mobil__sidebar-hasdropdown--active")
              toggleDropdown = false;
            } else {
              SlideUp(mobileDropdown[idx], "fast");
              hasdropdown[idx].classList.remove("mobil__sidebar-hasdropdown--active")
              toggleDropdown = true;
            }
          });
        })
      });
    }
    const languageResponsive = document.querySelector(".language-button");
    if (languageResponsive) {
      let dropDown = document.querySelector<HTMLDivElement>(".language__dropdown");
      let toggle = true;
      languageResponsive.addEventListener("click", function () {

        if (toggle) {
          showDropdown();
        } else {
          closelanguageDropDown();
        }
        toggle = !toggle;

        closeOverlay.addEventListener("click", function () {
          closelanguageDropDown();
          toggle = true;
        });

        function showDropdown() {
          SlideDown(dropDown, "fast");
          closeOverlay.classList.add("nolato__active");
        }
        function closelanguageDropDown() {
          SlideUp(dropDown, "fast");
          closeOverlay.classList.remove("nolato__active");
        }
      });
    }
  }
}

closeOverlay.addEventListener("click", function () {
  indexCheck = null;
  closeDropdown();
});

function closeDropdown() {
  if (dropdown) {
    dropdown.style.display = "none";
    SlideUp(dropdown, "fast");
  }
  closeOverlay.classList.remove("nolato__active");
  dropdown = null;
}

const searchButton = document.querySelector(".nav__info-search-button");
if (searchButton) {
  const navbar = document.querySelector(".nav__navbar");
  const searchContainer = document.querySelector(".nav__search");
  const closeSearchButton = document.querySelector(".nav__search-close-button");
  const inputField = document.querySelector<HTMLElement>("#searchInputHeader");

  searchButton.addEventListener("click", function () {

    navbar.classList.add("display-none");
    searchButton.classList.add("display-none");
    searchContainer.classList.remove("display-none");
    closeOverlay.classList.add("nolato__active");
    inputField.focus();

    function closeSearchContainer() {
      navbar.classList.remove("display-none");
      searchButton.classList.remove("display-none");
      searchContainer.classList.add("display-none");
      closeOverlay.classList.remove("nolato__active");
    }

    closeOverlay.addEventListener("click", function () {
      closeSearchContainer();
    });

    closeSearchButton.addEventListener("click", function () {
      closeSearchContainer();
    });
  });
}
const languageDropDownButton = document.querySelector(".nav__info-language");

if (languageDropDownButton) {
  const dropdownLanguage = document.querySelector(".nav__info-dropdown");
  let toggle = true;
  languageDropDownButton.addEventListener("click", function () {
    if (toggle) {
      showDropdown();
    } else {
      closelanguageDropDown();
    }
    toggle = !toggle;
    closeOverlay.addEventListener("click", function () {
      closelanguageDropDown();
      toggle = true;
    });

    function showDropdown() {
      dropdownLanguage.classList.add("nolato__active");
      closeOverlay.classList.add("nolato__active");
    }
    function closelanguageDropDown() {
      dropdownLanguage.classList.remove("nolato__active");
      closeOverlay.classList.remove("nolato__active");
    }
  });

}

const searchResponsive = document.querySelector<HTMLElement>(".search-button");
if (searchResponsive) {
  const responsiveContainer = document.querySelector<HTMLElement>(".responsive-container");
  const responsiveSearchField = document.querySelector<HTMLElement>(".responsive-searchfield");
  const textField = document.querySelector<HTMLElement>(".responsive-searchfield__textfield");
  const closeButton = document.querySelector<HTMLElement>(".close-button");
  searchResponsive.addEventListener("click", function () {
    showSerchField()
    closeButton.addEventListener("click", function () {
      hideSerchField()
    })
    function showSerchField() {
      responsiveSearchField.style.display = "flex";
      responsiveContainer.style.display = "none"
      closeButton.classList.add("toggle");
      textField.focus();
    }
    function hideSerchField() {
      responsiveSearchField.style.display = "none";
      responsiveContainer.style.display = "flex"
      closeButton.classList.add("remove");
    }
  })
}


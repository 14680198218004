import cssVars from 'css-vars-ponyfill';
// lazysizes for lazy loading images etc https://github.com/aFarkas/lazysizes
import 'lazysizes';
// lazysizes unveilhooks plugin for backgrounds etc. https://github.com/aFarkas/lazysizes/tree/master/plugins/unveilhooks
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// Import other typescript files here.
import "./components/nolatoNavigation";
import "./components/buildsubmenu";
import "./components/selectPDF";
import "./components/pdfmodal";
import "./components/filter";
import "./components/cardCollapseSection";
import "./components/header";
import "./components/headerselecttext";
import "./components/contactmodal";
import "./components/faq";
import "./components/scrolldown"
import "./components/ScrollFunctions";
// import "./components/iframe-resize";
import "./components/iconClickFunctions";
import "./components/responsive";
import "./components/employeeCollapse";
import "./components/boardDirectors";
import "./components/renderAllPositions";
import "./components/recaptcha";
import "./components/pressReleasesScroll";
 import "./components/cookie";
import "./components/hotSpots";
import "./components/hero";
import "./components/filteredContacts";
import "./components/iframeFlyout";
import "./components/listAndImage";
import "./components/lazyLoadImage";
import "./components/tickerHolder";

window.addEventListener('load', (event) => {
  $("body").removeClass("preload");
    console.clear();
  });
import { ForEach, SlideDown, SlideUp } from "../helpers/dom";
const headerSelectTexts = document.querySelectorAll(".headerselecttext");

if (headerSelectTexts.length && headerSelectTexts) {
  window.addEventListener('resize', function () {
    selectText()
  })
  selectText()

}
function selectText() {
  ForEach(headerSelectTexts, function (headerSelectText, index) {
    let textButton = Array.prototype.slice.call(headerSelectText.children[0].children[0].children[0].children[1].children);
    let textSelection = headerSelectText.children[0].children[0].children[1].children;
    if ($(window).width() > 987) {
      ForEach(textButton, function (btn, idx) {
        let activeContainer = document.querySelectorAll(".headerselecttext__select-text--active")[index];
        getTextItems(activeContainer.children);
        btn.addEventListener("click", function () {
          resetClasses();
          textButton[idx].classList.add(
            "headerselecttext__select-button--active"
          );
          textSelection[idx].classList.add(
            "headerselecttext__select-text--active"
          );
          let textContainers = textSelection[idx].children as HTMLCollection;

          getTextItems(textContainers);
        });
        function getTextItems(textContainers) {
          for (let i = 0; i < textContainers.length; i++) {
            if (textContainers[i].style.animation) {
              textContainers[i].style.animation = "";
            }
            textContainers[
              i
            ].style.animation = `nolatoFadeIn 500ms ease forwards ${i / 7}s`;
          }
        }
        function resetClasses() {
          if (textButton.length === textSelection.length) {
            for (let i = 0; i < textButton.length; i++) {

              textButton[i].classList.remove(
                "headerselecttext__select-button--active"
              );
              textSelection[i].classList.remove(
                "headerselecttext__select-text--active"
              );
            }
          }
        }
      });
    } else {
      let index = null;
      let textCollapseButton = document.querySelectorAll(".headerselecttext__select-text--collapse");
      let textButtonResp = Array.prototype.slice.call(headerSelectText.children[0].children[0].children[0].children[1].children);
      let textSelectionResp = Array.prototype.slice.call(headerSelectText.children[0].children[0].children[1].children);
      if (textButtonResp.length === textSelectionResp.length) {
        ForEach(textSelectionResp, function (text, idx) {
          textButton[idx].appendChild(text);
        });
        ForEach(textButtonResp, function (btn, idx) {
          btn.addEventListener("click", function () {
            let container = textSelectionResp[idx] as HTMLElement;
            SlideDown(container, 300);
            if (index === idx) {
              removeClasses(textCollapseButton[idx]);
              index = null;
              return;
            }
            textCollapseButton[idx].textContent = "Less";
            textCollapseButton[idx].classList.add(
              "headerselecttext__select-text--collapse--active"
            );
            index = idx;
            function removeClasses(collapseButton) {
              collapseButton.textContent = "More";
              textCollapseButton[idx].classList.remove(
                "headerselecttext__select-text--collapse--active"
              );
              SlideUp(container, 300);
            }
          });
        });
      }
    }
  })
}

let filteredContacts = <HTMLDivElement>(
  document.querySelector(".filteredContacts")
);
import { getData } from "../helpers/dom";
const selectionContainer = document.querySelector(".filteredContacts__row ");
let outPutContainer: HTMLDivElement;
if (filteredContacts) {
  let ID = $(filteredContacts).attr("data-ID");
  getData(`/MainComponents/GetFilteredContacts?ID=${ID}`).then((data) => {
    let regions = data.Regions;
    let indexRegions = 0;
    let regionsTemplate = "";

    for (let region of regions) {
      regionsTemplate += `
            <option value="${indexRegions}">${region.Label}</option>

            `;
      indexRegions++;
    }
    let regionsContainer = `
        <div class="form-group col-md-3 col-xs-6">
            <label for="inputIndustry">${data.PlaceholderContinent}</label>
            <select class="form-control filteredContacts__continent">
            <option value="" >${data.PlaceholderContinent}</option>
            ${regionsTemplate}
            </select>
        </div>

        `;
    selectionContainer.insertAdjacentHTML("beforeend", regionsContainer);
    const regionsSelector = <HTMLSelectElement>(
      document.querySelector(".filteredContacts__continent")
    );

    regionsSelector.addEventListener("change", () => {
      if (!regionsSelector.value.length) {
        selectionContainer.children[1].remove();
        let output = document.querySelectorAll(".filteredContacts__output");
        if (output.length) {
          outPutContainer.innerHTML = "";
        }
      } else {
        renderCountries(regionsSelector, regions, data.PlaceholderCountry);
      }
    });
  });
}
function renderCountries(element, regions, placeholder) {
  if (selectionContainer.children[1]) {
    selectionContainer.children[1].remove();
  }
  let idx = element.value;
  let countries = regions[idx].Countries;
  let region = regions[idx].Label;
  let countriesTemplate = "";
  let indexCountires = 0;
  for (let country of countries) {
    countriesTemplate += `
            <option value="${indexCountires}">${country.Label}</option>

        `;
    indexCountires++;
  }
  let countriesContainer = `
    <div class="form-group col-md-3 col-xs-6 contact-modal">
        <label for="inputIndustry">${region}</label>
        <select class="form-control filteredContacts__countries">
        <option value="" >${placeholder}</option>
            ${countriesTemplate}
        </select>
    </div>
    `;
  selectionContainer.insertAdjacentHTML("beforeend", countriesContainer);
  const countriesSelector = <HTMLSelectElement>(
    document.querySelector(".filteredContacts__countries")
  );
  countriesSelector.addEventListener("change", () => {
    if (!countriesSelector.value.length) {
      selectionContainer.children[1].remove();
    } else {
      renderOutput(countriesSelector, countries);
    }
  });
}

function renderOutput(ele, countries) {
  outPutContainer = document.querySelector(".filteredContacts__bottom");
  outPutContainer.innerHTML = "";
  let idx = ele.value;
  let agencies = countries[idx].Agencies;
  let agencyTemplates = "";

  for (let agency of agencies) {
    agencyTemplates += `
        <div class="filteredContacts__output">
            <div class="filteredContacts__output--header">
                <h3>${agency.Title}</h3>
            </div>
            <div class="filteredContacts__output--body rich__text textStyle-2">
                <p>${agency.Location}</p>
                <ul class="filteredContacts__output--items">
                    ${
                      agency.Phone.length
                        ? `<li class="filteredContacts__output--item">${agency.Phone}</li>`
                        : ""
                    }
                    ${
                      agency.Fax.length
                        ? `<li class="filteredContacts__output--item">Fax: ${agency.Fax}</li>`
                        : ""
                    }
                    ${
                      agency.URL.length
                        ? `<li class="filteredContacts__output--item">${agency.URL}</li>`
                        : ""
                    }
                    ${
                      agency.Email.length
                        ? `<li class="filteredContacts__output--item"><a href="mailto:${agency.Email}">${agency.Email}</a></li>`
                        : ""
                    }
                </ul>
            </div>
        </div>

        `;
  }
  outPutContainer.insertAdjacentHTML("beforeend", agencyTemplates);
}

import { ForEach } from "../helpers/dom";
let employeeCpntainer = document.querySelector(".board-directors");
if (employeeCpntainer) {
  window.addEventListener("resize", function () {
    if ($(window).width() > 1127) {
      for (let i = 0; i < 9; i++) {
        let textContainers = document.querySelectorAll(
          `.board-directors__items-item-text-${i + 1}`
        );
        let height = 0;
        ForEach(textContainers, function (textContainer: HTMLElement, idx) {
          if (textContainer.clientHeight > height) {
            height = textContainer.clientHeight;
          }
        });
        ForEach(textContainers, function (textContainer: HTMLElement, idx) {
          textContainer.style.height = `${height}px`;
        });
      }
    }
  });
}

// This funktion change every textHeight in Emoployee-component to line it.
// The loop with length of 8 is beacuse they got 9 diffrent info titles for every emoplyee.

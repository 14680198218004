import Helper from "./helper";
import { ForEach } from "../helpers/dom";

const pdfModalButtons = document.querySelectorAll<HTMLElement>(".onclick__pdf-modal");
if (pdfModalButtons.length) {
    let overlay = Helper.overlay;
    const wrapperContainer = document.querySelector("#wrapper");
    let PdfModal: HTMLElement;

    ForEach(pdfModalButtons, function (modal:HTMLElement, idx) {
        modal.addEventListener("click", function (e) {
            e.preventDefault();
            let data = JSON.parse(modal.dataset.publication);
            buildModal(data)
        })
        overlay.addEventListener("click", function () {
            closeModal(PdfModal)
        })

        function closeModal(modal) {
            $("body").css("overflow", "auto");
            overlay.classList.remove("nolato__overlay");
            overlay.classList.remove("nolato__active");
            $(modal).remove();
        }
        function setAttributesFunction(el, attribute) {
            
            for (let key in attribute) {
                el.setAttribute(key, attribute[key]);
            }
        }

        function checkActiveButtons(el, data, val) {
            if (data) {
                el.style.display = "flex";
                setAttributesFunction(el, val)
            } else {
                el.style.display = "none";
            }
        }
        function buildModal(data) {
            let PublicationFiles = data.PublicationFiles;
            
            let selection = ``;
            for (let i = 0; i < PublicationFiles.length; i++) {
                let files = PublicationFiles[i];
                selection += `
                <option value="${files.PublicationLinkUrl}">${files.PublicationLinkName}</option>
                `;
            }
            let template = `
                            <div class="pdf-modal">
                                <div class="pdf-modal__close">
                                    <span></span>
                                </div>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 col-md-11 pdf-modal__container">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-md-4 d-none d-md-block">
                                                        <img class="pdf-modal__img" src="${data.PublicationThumbnail}" alt="${data.PublicationThumbnailAlt}">
                                                    </div>
                                                    <div class="col-md-8 pdf-modal__text-column">
                                                        <div>
                                                            <h4 class="pdf-modal__container-title">
                                                                ${data.PublicationName ? data.PublicationName : "Nolatos Annual Report Error"}
                                                            </h4>
                                                        </div>
                                                        <div>
                                                            <p class="pdf-modal__container-text">
                                                            ${data.PublicationDescription ? data.PublicationDescription : ""}
                                                            </p>
                                                        </div>
                                                        <div class="pdf-modal__container-select-language">
                                                            <div>
                                                                <span>${data.ChooseLanguageText ? data.ChooseLanguageText : "Choose your language"}</span>
                                                            </div>
                                                            <div class="pdf-modal__container-select-language-select">
                                                            <select class="pdf-modal__selection">
                                                                    ${selection}
                                                            </select>
                                                            </div>
                                                        </div>
                                                        <div class="pdf-modal__container-buttons">
                                                            <div class="btn-container">
                                                                <a class="btn-default btn-default-thin btn-backgroundcolor-red pdf-modal__container-buttons-read" target="_blank"
                                                                    href="">${data.PublicationReadText ? data.PublicationReadText: "Read"}</a>
                                                            </div>
                                                            <div class="btn-container">
                                                                <a class="btn-default btn-default-thin btn-backgroundcolor-red pdf-modal__container-buttons-download "
                                                                    href="">${data.PublicationDownloadText ? data.PublicationDownloadText : "Ladda Ner"}</a>
                                                            </div>
                                                            <div class="btn-container ">
                                                                <a class="btn-default btn-default-thin pdf-modal__container-buttons-order" href="">
                                                                    ${data.PublicationOrderText ? data.PublicationOrderText : "Order"}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            `
            wrapperContainer.insertAdjacentHTML('beforeend', template);
            $("body").css("overflow", "hidden");
            let select = document.querySelector<HTMLInputElement>(".pdf-modal__selection");
            let closebutton = document.querySelector(".pdf-modal__close");
            PdfModal = document.querySelector(".pdf-modal")
            overlay.classList.add("nolato__overlay");
            overlay.classList.add("nolato__active");
            updateModal()

            select.onchange = function () {
                updateModal()
            }

            closebutton.addEventListener("click", function () {
                closeModal(PdfModal)
            })

            function updateModal() {
                let readButton = document.querySelector(".pdf-modal__container-buttons-read");
				if( select.value.indexOf("?")<0 ) {
					checkActiveButtons(readButton, data.PublicationRead, { "href": select.value + "?mode=brochure" });
				} else {
					checkActiveButtons(readButton, data.PublicationRead, { "href": select.value + "&mode=brochure" });
				}

                let downloadButton = document.querySelector(".pdf-modal__container-buttons-download");
                checkActiveButtons(downloadButton, data.PublicationDownload, { "href": select.value, "download": select.value });


                let orderButton = document.querySelector(".pdf-modal__container-buttons-order")                
                checkActiveButtons(orderButton, data.PublicationOrder, { "href": data.PublicationOrderForm });
            }

        }
    })
}

import { ForEach, scrollToElement } from "../helpers/dom";
const secondaryMenu = document.querySelector<HTMLDivElement>(".secondary-menu");
const toggleDropdownBtn = document.querySelector<HTMLButtonElement>(
  "button[data-toggleDropdown]"
);
const subMenu = <HTMLDivElement>(
  document.querySelector(".secondary-menu__links")
);

let device = window.innerWidth <= 992 ? "mobile" : "desktop";

const windowRisizeHandler = (e) => {
  let currentDevice = window.innerWidth <= 992 ? "mobile" : "desktop";
  if (device === currentDevice) return;
  else {
    device = currentDevice;
    closeSubMenu();
    subMenu.style.removeProperty("max-height");
  }
};

let activeSubMenu = null;
function closeSubMenu() {
  activeSubMenu = null;
  subMenu.style.maxHeight = "0px";
  subMenu.style.marginBottom = "0rem";
  toggleDropdownBtn.style.color = "black";
  toggleDropdownBtn.classList.remove("secondary-menu__toggleDropdown--active");
  window.removeEventListener("click", handleEvent);
}

if (subMenu) {
  let listItem = document.querySelectorAll(".page-nav-section");
  const listItemPageLinks = document.querySelectorAll(".page-nav-section-external");
  const listItems = listItem.length + listItemPageLinks.length
  
  // Reverse listItem to order them and insertBefore
  listItem = [...listItem];
  listItem = listItem.reverse()

  if (listItems < 1) {
    secondaryMenu.style.display = "none";
  } else {
    let target = null;
    ForEach(listItem, function (item, idx) {
      let liTag = document.createElement("li");
      let divTag = <HTMLDivElement>document.createElement("div");
      let anchorTag = <HTMLAnchorElement>document.createElement("a");
      anchorTag.setAttribute("href", `#${item.id}`);
      anchorTag.textContent = item.dataset.name;
      subMenu.insertBefore(liTag, subMenu.firstChild);
      //subMenu.appendChild(liTag);
      liTag.appendChild(divTag);
      divTag.appendChild(anchorTag);
      let container = document.getElementById(`${item.id}`);
      anchorTag.addEventListener("click", function (e) {
        e.preventDefault();

        scrollToElement(container, 400, 280);
        
        if (target === null || target !== e.target) {
          // if (toggleDropdownBtn) {
          //   if (secondaryMenu.classList.contains("secondary-menu__fixed")) {
          //     if (device === "desktop") {
          //       console.log("clciky 1")
          //       scrollToElement(container, 400, 280);
          //     } else {
          //       console.log("clciky 2")
          //       scrollToElement(container, 400, 280);
          //     }
          //   } else {
          //     if (device === "desktop") {
          //       console.log("clciky 3")
          //       scrollToElement(container, 400, 280);
          //     } else {
          //       console.log("clciky 4")
          //       scrollToElement(container, 400, 280);
          //     }
          //   }
          // } else {
          //   console.log("clciky 5b 280 s")
          //   scrollToElement(container, 400, 280);
          // }

          window.location.hash = item.id;
          target = e.target;
        }
        if (toggleDropdownBtn && activeSubMenu) {
          closeSubMenu();
        }
      });
    });

    if (toggleDropdownBtn) {
      window.addEventListener("resize", windowRisizeHandler);

      toggleDropdownBtn.addEventListener("click", (e) => {
        if (activeSubMenu) {
          closeSubMenu();
        } else {
          closeOnClickOutside(secondaryMenu, toggleDropdownBtn);
          activeSubMenu = "active";
          subMenu.style.maxHeight = subMenu.scrollHeight + "px";
          subMenu.style.marginBottom = "1rem";
          toggleDropdownBtn.style.color = "#E60000";
          toggleDropdownBtn.classList.add(
            "secondary-menu__toggleDropdown--active"
          );
        }
      });
    }
  }
}

let handleEvent;
function closeOnClickOutside(targetItem: HTMLElement, toggleItem: HTMLElement) {
  handleEvent = (e) => {
    const isClickOnItem = targetItem.contains(e.target);
    const isClickOnToggleItem = toggleItem.contains(e.target);

    if (!isClickOnItem && !isClickOnToggleItem) {
      closeSubMenu();
    }
  };
  window.addEventListener("click", handleEvent);
}


// On page load scroll to Anchor if exists
window.addEventListener('load', function(event) {

  var currentUrl = document.URL,
   urlParts   = currentUrl.split('#');
     
  if(urlParts.length > 1)
  {
    var block = document.getElementById(urlParts[1]);
    
    if(block)
      scrollToElement(block, 400, 280);
    
  }
});

// This Component builds the Sub Menu. For every component on the page that got the class page-nav-section
// Will get rendered in the menu, then every anchortag gets a eventlistener to scroll to right component.

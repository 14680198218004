import { scrollToElement } from "../helpers/dom";

var nolatoNavigation = (function() {
    var Init = function () {

        MainNavigation()
        DropOuts()
        SearchButton()
        LanguageSelector()
        BurgerMenu()
        CellPhoneNavigationClose()
        ResizeHandler()
        SiteOpacityHandler()        
        LinksHandler()
    }

    var LinksHandler = function () {

        var mainMenu = document.querySelector('.nolatoNavigation__menu')       
        var links = mainMenu.querySelectorAll('a');

        links.forEach(link  => {

            link.addEventListener("click", function (e) {
                e.preventDefault();
                
                CloseAllFunctions()
                
                window.location = link.href

                var hasAnchor = link.href.split('#')

                let scrollToContainer = document.getElementById(`${hasAnchor[1]}`);
                if(scrollToContainer != null)
                {
                    scrollToElement(scrollToContainer, 400, 280);
                }
            })

        })
    }

    var CloseAllFunctions = function () {
        CloseLanguageSelector()
        CloseAllDropOuts()
        CloseAllDropDowns()
        RemoveAllActiveChildIcons()
        RemoveAllTogglerIcons()
        RemoveAllOpacities()
        CloseMenuOpacity()
        CloseActiveDropdowns()
        document.body.style.overflow = "auto"  
    }

    var SiteOpacityHandler = function () {

        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")
        var mainMenu = document.querySelector('.nolatoNavigation__menu')
        var burgerMenu = document.querySelector(".nolatoNavigation__burgerMenu")

        menuSiteOpacity.addEventListener("click", () => {
            CloseLanguageSelector()
            CloseAllDropOuts()
            CloseAllDropDowns()
            RemoveAllActiveChildIcons()
            RemoveAllTogglerIcons()
            RemoveAllOpacities()
            CloseMenuOpacity()
            CloseActiveDropdowns()

            mainMenu.classList.remove('nolatoNavigation__burgerMenu--activeMain')            
            burgerMenu.classList.remove("nolatoNavigation__burgerMenu--active")
            document.body.style.overflow = "auto"   
        })
    }

    var SearchButton = function () {

        const searchIcon = document.querySelector(".nolatoNavigation__search--icon")
        const menuSearchClose = document.querySelector(".nolatoNavigation__searchField-closeButton")

        if(searchIcon)
        searchIcon.addEventListener("click", () => SearchActivate())        

        if(menuSearchClose)
        menuSearchClose.addEventListener("click", () =>SearchActivate())        
    }

    var MainNavigation = function () {

        const mainNavigationTogglers = document.querySelectorAll(".nolatoNavigation__menu--item-toggler")

        mainNavigationTogglers.forEach((mainTab, index) => {

            var dropDown = mainTab.parentElement.querySelector('.nolatoNavigation__dropdown');            
            var closeButton = mainTab.parentElement.querySelector('.nolatoNavigation__menu-close');
        
            if(closeButton != null)
            closeButton.addEventListener("click", () => {
                
                CloseActiveSubDropOuts(dropDown)

                dropDown.classList.remove("nolatoNavigation__dropdown--show")
                mainTab.classList.remove("nolatoNavigation__menu--item-toggler--active")   
                   
                var openChildren = dropDown.querySelectorAll('.nolatoNavigation__menu-listItem--children--active')        
                openChildren.forEach(icon => { icon.classList.remove('nolatoNavigation__menu-listItem--children--active') })
            
                const activeTogglers = dropDown.querySelectorAll(".nolatoNavigation__menu--item-toggler--active")
                activeTogglers.forEach(activeToggler => { activeToggler.classList.remove("nolatoNavigation__menu--item-toggler--active") })     

                CloseActiveDropdowns() 
                BodyScroll()
                
                // HÄR
                
                if(window.innerWidth > 1100)
                {
                    document.body.style.overflow = "auto"
                }
                else {
                    document.body.style.overflow = "hidden"
                }

                
            })
            
            mainTab.dataset.toggle= index

            if(dropDown)
            dropDown.dataset.dropdown = index

            mainTab.addEventListener("click", () => { 

                if(dropDown != null)        
                dropDown.classList.toggle("nolatoNavigation__dropdown--show")      
            
                CloseNotActiveTogglers(index)
                mainTab.classList.toggle("nolatoNavigation__menu--item-toggler--active")
                mainTab.classList.toggle("activeDropdown")
                BodyScroll()
                
            })
        })
    }

    var BodyScroll = function () {
        // Hide scroll if any open and handle site opacity
        var menuActive = document.querySelectorAll(".activeDropdown")
        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")
        var mainMenu = document.querySelector('.nolatoNavigation__menu')      

        if(window.innerWidth > 1100)
        {
            if(menuActive.length > 0)
            {
                menuSiteOpacity.classList.add("nolatoNavigation__siteopacity--show")
                document.body.style.overflow = "hidden"    
            }
            else {
                menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")                
                document.body.style.overflow = "auto"
            }
            
        }
        else {
            
            if(mainMenu.classList.contains('nolatoNavigation__burgerMenu--activeMain'))
            {
                menuSiteOpacity.classList.add("nolatoNavigation__siteopacity--show")
                document.body.style.overflow = "hidden"    
            }
            else {
                menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")       
                document.body.style.overflow = "auto"     
            }
        }        
    }

    var ResizeHandler = function () {
        const mainMenu = document.querySelector('.nolatoNavigation__menu')
        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")

        window.addEventListener("resize",() => {

            var menuActive = document.querySelectorAll(".activeDropdown")
            
            if(window.innerWidth > 1100)
            {
                menuActive.length > 0
                    ? menuSiteOpacity.classList.add("nolatoNavigation__siteopacity--show")
                    : menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")                            
            }
            else {
                
                mainMenu.classList.contains('nolatoNavigation__burgerMenu--activeMain')
                    ? menuSiteOpacity.classList.add("nolatoNavigation__siteopacity--show")
                    : menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")                                       
            }        
        });    
    }

    var CellPhoneNavigationClose = function () {
        var cellphoneClose = document.querySelector(".nolatoNavigation__menu-mobileClose")
        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")

        if(cellphoneClose)
        {
            const menu = document.querySelector(".nolatoNavigation__menu")
            const burgerMenu = document.querySelector(".nolatoNavigation__burgerMenu")
            
            cellphoneClose.addEventListener("click", () => {
                CloseAllDropOuts()
                CloseAllDropDowns()
                RemoveAllActiveChildIcons()
                RemoveAllTogglerIcons()
                BodyScroll()


                menu.classList.remove("nolatoNavigation__burgerMenu--activeMain") 
                burgerMenu.classList.remove("nolatoNavigation__burgerMenu--active")
                menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")
    
                DocumentScrollToggle(menu)
            })         
        }
    }   

    var DropOuts = function () {

        var navId = 1;
        var dropOutTogglers = document.querySelectorAll('.nolatoNavigation__menu-listItem--children');

        if(dropOutTogglers && dropOutTogglers.length > 0)
        {
            dropOutTogglers.forEach((icon, index) => {                
                
                // Connect Icon to flyout with a navId
                var extender = icon.nextElementSibling;                
                icon.dataset.extendertrigger = navId
                extender.dataset.extenderdrop = navId
                navId++

                // Toggle Flyouts with binding from buttons
                icon.addEventListener("click", () => {

                    var currentLevel = icon.parentElement.parentElement.parentElement
                    var currentLevelIcons = currentLevel.querySelectorAll(".nolatoNavigation__menu-listItem--children")
                    var activeId = icon.dataset.extendertrigger

                    currentLevelIcons.forEach(currentIcon => {
                        // Handle Current Extender
                        var currentExtender = currentIcon.nextElementSibling;

                        // Handle Current Backbutton
                        var backButton = currentIcon.parentElement.querySelector('.nolatoNavigation__menu-back');

                        if(backButton != null)
                        backButton.addEventListener("click", () => {
                            currentExtender.classList.remove("nolatoNavigation__dropout--show")     
                            currentIcon.classList.remove("nolatoNavigation__menu-listItem--children--active")                            
                            currentExtender.parentElement.classList.remove("nolatoNavigation__menu-listItem--active")   
                            
                            let closeSubDropouts = backButton.parentElement.querySelectorAll(".nolatoNavigation__dropout--show")
                            closeSubDropouts.forEach(subDrop => subDrop.classList.remove("nolatoNavigation__dropout--show"))

                            let closeSubListItems = backButton.parentElement.querySelectorAll(".nolatoNavigation__menu-listItem--active")
                            closeSubListItems.forEach(subList => subList.classList.remove("nolatoNavigation__menu-listItem--active"))
                        })                        

                        if(currentIcon.dataset.extendertrigger == activeId)
                        {
                            currentExtender.classList.toggle("nolatoNavigation__dropout--show")      
                            currentIcon.classList.toggle("nolatoNavigation__menu-listItem--children--active")                            
                            currentExtender.parentElement.classList.toggle("nolatoNavigation__menu-listItem--active")
                        }
                        else {
                            currentExtender.classList.remove("nolatoNavigation__dropout--show")       
                            currentIcon.classList.remove("nolatoNavigation__menu-listItem--children--active")                            
                            currentExtender.parentElement.classList.remove("nolatoNavigation__menu-listItem--active")
                        }
                    })
                })
            })
        }
    }

    var LanguageSelector = function () {
        
        const languageSelector = document.querySelector('.nolatoNavigation__languageSelector')       

        if(languageSelector)
        {
            languageSelector.addEventListener("click", () => LanguageToggle())

            function LanguageToggle() {
                CloseSearch()
                const languages = document.querySelector('.nolatoNavigation__languageSelector--box-language--available')
                languages.classList.toggle('nolatoNavigation__languageSelector--box-language--show')
            }
        }
    }

    var BurgerMenu = function () {
        var burgerMenu = document.querySelector('.nolatoNavigation__burgerMenu')        
        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")

        if(burgerMenu)
        {
            const mainMenu = document.querySelector('.nolatoNavigation__menu')

            burgerMenu.addEventListener("click", () => {
                
                if(mainMenu.classList.contains('nolatoNavigation__burgerMenu--activeMain'))
                {
                    burgerMenu.classList.remove('nolatoNavigation__burgerMenu--active')
                    mainMenu.classList.remove('nolatoNavigation__burgerMenu--activeMain')
                    menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")
    
                }
                else {
                    burgerMenu.classList.add('nolatoNavigation__burgerMenu--active')
                    mainMenu.classList.add('nolatoNavigation__burgerMenu--activeMain')   
                    menuSiteOpacity.classList.add("nolatoNavigation__siteopacity--show")
                }

                DocumentScrollToggle(mainMenu)
            })
        }        
    }

    function SearchActivate() {
        const searchIcon = document.querySelector(".nolatoNavigation__search--icon")
        const searchField = document.getElementById("menuSearchField")
        const searchHolder = document.querySelector(".nolatoNavigation__search-holder")
        const menuSearchField = document.querySelector(".nolatoNavigation__searchField")        
        const menu = document.querySelector(".nolatoNavigation__menu")      
        
        CloseLanguageSelector()
        CloseAllDropOuts()
        CloseAllDropDowns()
        RemoveAllActiveChildIcons()
        RemoveAllTogglerIcons()
        RemoveAllOpacities()
        CloseMenuOpacity()
        
        searchHolder.classList.toggle("nolatoNavigation__search-holder--hidden")
        searchIcon.classList.toggle("nolatoNavigation__search--icon--active")
        menu.classList.toggle("nolatoNavigation__menu-hidden")
        menuSearchField.classList.toggle("nolatoNavigation__searchField--visible")
        searchField.focus()
    }

    function CloseNotActiveTogglers(activeIndex) {

        const mainNavigationTogglers = document.querySelectorAll(".nolatoNavigation__menu--item-toggler")

        mainNavigationTogglers.forEach((mainTab, index) => {
    
            var dropDown = mainTab.parentElement.querySelector('.nolatoNavigation__dropdown');
    
            if(index != activeIndex)
            {
                mainTab.classList.remove("nolatoNavigation__menu--item-toggler--active")
    
                if(dropDown != null)            
                    dropDown.classList.remove("nolatoNavigation__dropdown--show")
    
                mainTab.classList.remove("activeDropdown")
                
                CloseAllDropOuts()
                RemoveAllOpacities()
                RemoveAllActiveChildIcons()
            }       
        })
    }

    function CloseActiveSubDropOuts(item) {
        var activeFlyouts = item.querySelectorAll(".nolatoNavigation__dropout--show")

        if(activeFlyouts && activeFlyouts.length > 0)
        {
            activeFlyouts.forEach(subDropOut => {
                subDropOut.classList.remove("nolatoNavigation__dropout--show")
            })
        }

        var activeOpacities = item.querySelectorAll(".nolatoNavigation__menu-opacity--active")
        
        activeOpacities.forEach(subOpacity => {
            subOpacity.classList.remove("nolatoNavigation__menu-opacity--active")
        })
    }

    function CloseAllDropDowns() {
        var dropDowns = document.querySelectorAll('.nolatoNavigation__dropdown--show');
        dropDowns.forEach((drop) => { drop.classList.remove("nolatoNavigation__dropdown--show") })
        CloseActiveDropdowns()
    }
    
    function CloseAllDropOuts() {
        var dropOutTogglers = document.querySelectorAll('.nolatoNavigation__dropout--show');
        dropOutTogglers.forEach((dropOut) => { dropOut.classList.remove("nolatoNavigation__dropout--show") })
    }

    function CloseMenuOpacity() {
        var menuSiteOpacity = document.querySelector(".nolatoNavigation__siteopacity")
        menuSiteOpacity.classList.remove("nolatoNavigation__siteopacity--show")
    }
    
    function CloseActiveDropdowns() {
        var activeDropdowns = document.querySelectorAll(".activeDropdown")   
        activeDropdowns.forEach(x => x.classList.remove("activeDropdown"))
    }    

    function RemoveAllOpacities() {
        var activeOpacities = document.querySelectorAll('.nolatoNavigation__menu-opacity--active')        
        activeOpacities.forEach(opacityElement => { opacityElement.classList.remove('nolatoNavigation__menu-opacity--active') })
    }

    function CloseSearch() {
        
            const searchIcon = document.querySelector(".nolatoNavigation__search--icon")
            const searchField = document.getElementById("menuSearchField")
            const searchHolder = document.querySelector(".nolatoNavigation__search-holder")
            const menuSearchField = document.querySelector(".nolatoNavigation__searchField")        
            const menu = document.querySelector(".nolatoNavigation__menu")                 
            
            searchHolder.classList.remove("nolatoNavigation__search-holder--hidden")
            searchIcon.classList.remove("nolatoNavigation__search--icon--active")
            menu.classList.remove("nolatoNavigation__menu-hidden")
            menuSearchField.classList.remove("nolatoNavigation__searchField--visible")            
    }

    function CloseLanguageSelector() {
        const languageSelector = document.querySelector('.nolatoNavigation__languageSelector--box-language--available')  
        if(languageSelector)
        {
            languageSelector.classList.remove("nolatoNavigation__languageSelector--box-language--show")        
        }        
    }
    
    function RemoveAllActiveChildIcons() {
        var openChildren = document.querySelectorAll('.nolatoNavigation__menu-listItem--children--active')        
        openChildren.forEach(icon => { icon.classList.remove('nolatoNavigation__menu-listItem--children--active') })
    }

    function RemoveAllTogglerIcons() {
        const activeTogglers = document.querySelectorAll(".nolatoNavigation__menu--item-toggler--active")
        activeTogglers.forEach(activeToggler => { activeToggler.classList.remove("nolatoNavigation__menu--item-toggler--active") })        
    }

    function DocumentScrollToggle(mainMenu) {
        if(mainMenu.classList.contains("nolatoNavigation__burgerMenu--activeMain"))
        {
            document.body.style.overflowY = "hidden"
        }
        else {
            document.body.style.overflowY = "auto"
        }
    }

return { Init }
})()

nolatoNavigation.Init()
let pressReleaseAnchors = document.querySelectorAll<HTMLElement>(".pressReleaseYear");
import { ForEach, scrollToElement } from "../helpers/dom";
if (pressReleaseAnchors && pressReleaseAnchors.length) {
    ForEach(pressReleaseAnchors, function (pressReleaseAnchor: HTMLElement, idx) {
        let anchor = pressReleaseAnchor.dataset.year.toString();
        let pressReleaseContainer = document.getElementById(`${anchor}`)
        pressReleaseAnchor.addEventListener("click", function (e) {
            e.preventDefault();
            scrollToElement(pressReleaseContainer, 300);
        });
    })
}
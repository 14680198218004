import {ContactModal} from "../helpers/dom";
import { ForEach } from "../helpers/dom";

const modalButtons = document.querySelectorAll(".onclick__contact-modal");
if(modalButtons && modalButtons.length){
    ForEach(modalButtons, function (modalButton: HTMLElement, idx) {
        modalButton.addEventListener("click", function (e) {
            e.preventDefault();
            ContactModal();
        })
    })
}


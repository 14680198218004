import { ForEach, SlideDown, SlideUp } from "../helpers/dom";
let employeeCollapseButton = document.querySelectorAll <HTMLElement>(
  ".board-directors__title--collapse"
);
if (employeeCollapseButton && employeeCollapseButton.length) {
  let employeeContainer = document.querySelectorAll(".board-directors__items");
  ForEach(employeeCollapseButton, function (btn, idx) {
    let toggle = true;
    btn.addEventListener("click", function () {
      if (toggle) {
        SlideDown(employeeContainer[idx] as HTMLElement, 300);
        btn.classList.add("board-directors__title--active");
      } else {
        SlideUp(employeeContainer[idx] as HTMLElement, 300);
        btn.classList.remove("board-directors__title--active");
      }
      toggle = !toggle;
    });
  });
}

import { each } from "jquery";
import { ForEach } from "../helpers/dom";

$(document).ready(function () {
  $.each($(".recaptcha-element"), function (index) {
    $(this).attr("data-order", index);
  });

  $(".contact-modal__popup form").each(function () {
    var index = $(this).find(".recaptcha-element").attr("data-order");
    var valid = false;
    const $form = $(this);
    const $inputName: any = $("#inputName", $form);
    const $inputTitle: any = $("#inputTitle", $form);
    const $inputEmail: any = $("#inputEmail", $form);
    const $inputCompany: any = $("#inputCompany", $form);
    const $inputCountry: any = $("#inputCountry", $form);
    const $inputPhone: any = $("#inputPhone", $form);
    const $inputIndustry = $("#inputIndustry", $form).val();
    const $selectedIndustry: any = $("#inputIndustry", $form);
    const $inputMessage: any = $("#exampleFormControlTextarea1", $form);
    const $cbPolicy: any = $("#cbPolicy", $form);
    const $submitButton = $("#submitButton", $form);
    const requiredInputs = [
      $inputName[0],
      $inputEmail[0],
      $inputMessage[0],
      $inputCompany[0],
      $inputCountry[0],
      $cbPolicy[0],
    ];
    $.each(requiredInputs, function (i, requiredInput: any) {
      $(requiredInput).blur(function () {
        validateData([requiredInput]);
      });
    });

    $(this).submit(function (e) {
      e.preventDefault();
      var validation = validateData(requiredInputs);
      if (validation) {
        valid = true;
        grecaptcha.execute(index);
      } else {
        valid = false;
        grecaptcha.reset(index);
      }
    });
    window["submit" + index] = function (pos, resp) {
      $(".contactLoading__container").show();
      var data = {};
      $.ajax({
        type: "POST",
        url: "/api/sitecore/Contact/PostFlyoutForm",
        data: {
          token: resp,
          itemId: $form.data("page"),
          name: $inputName.val(),
          title: $inputTitle.val(),
          email: $inputEmail.val(),
          company: $inputCompany.val(),
          country: $inputCountry.val(),
          phone: $inputPhone.val(),
          industry: $selectedIndustry.val(),
          message: $inputMessage.val(),
          policy: "1",
        },
        success: function (data) {
          if (data.success === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submission",
              formCategory: "Flyout contact form",
            });
            $(".contactLoading__container").hide();
            $(".thank-you-message").show();
            $(".thank-you-message__responseHeadline").text(
              data.responseHeadline
            );
            $(".thank-you-message__responseMessage").text(data.responseMessage);
            $(".error-message").hide();
            $form.hide();
            resetForm();
          } else {
            resetForm();
            $(".contactLoading__container").hide();
            $(".error-message").show();
            $(".error-message__responseHeadline").text(data.responseHeadline);
            $(".error-message__responseMessage").text(data.responseMessage);
            $(".thank-you-message").hide();
            $form.hide();
            $(".error-message__button").click(function (e) {
              $form.show();
              $(".error-message").hide();
            });
          }
        },
        error: function (e) {
          $(".contactLoading__container").hide();
          $(".error-message").show();
          $(".error-message__responseHeadline").text("Error");
          $(".error-message__responseMessage").text(
            "Could not connect to server"
          );
          $(".thank-you-message").hide();
          $(".error-message__button").click(function (e) {
            $form.show();
            $(".error-message").hide();
          });
        },
      });
    };
    function resetForm() {
      $(".form-group").each(function () {
        $(this).find(".form-control").val("");
        $(this).removeClass("valid");
      });
      $(".policy").prop("checked", false);
    }
  });

  $(".newsletter-form").each(function () {
    var index = $(this).find(".recaptcha-element").attr("data-order");
    var valid = false;
    const $form = $(this);
    const $inputName: any = $("#nl_inputName", $form);
    const $inputEmail: any = $("#nl_inputEmail", $form);
    const $inputCompany: any = $("#nl_inputCompany", $form);
    let $inputs = $("input", $form);
    const requiredInputs = [$inputName[0], $inputEmail[0]];
    $.each(requiredInputs, function (i, requiredInput: any) {
      $(requiredInput).blur(function () {
        validateData([requiredInput]);
      });
    });

    $(this).submit(function (e) {
      e.preventDefault();
      var validation = validateData(requiredInputs);
      if (validation) {
        valid = true;
        grecaptcha.execute(index);
      } else {
        valid = false;
        grecaptcha.reset(index);
      }
    });

    window["submit" + index] = function (pos, resp) {
      $.ajax({
        type: "POST",
        url: "/api/sitecore/Contact/PostNewsletterForm",
        data: {
          token: resp,
          itemId: $form.data("page"),
          name: $inputName.val(),
          email: $inputEmail.val(),
          company: $inputCompany.val(),
        },
        success: function (data) {
          if (data.success === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submission",
              formCategory: "EMC Thermal newsletter singup",
            });
            $inputs.each(function (index) {
              let input = $inputs[index];
              $(input).attr("disabled", "true");
            });
            $("#nl_SubmitButton").attr("disabled", "true");
          } else {
            //view error
          }
        },
        error: function (e) {
          //view error
        },
      });
    };
    function validateData(requiredInputs) {
      let isValid = true;
      $.each(requiredInputs, function (i, requiredInput: any) {
        if ($(requiredInput).attr("type") === "text") {
          if (!$(requiredInput).val()) {
            $(this.nextElementSibling).show();
            isValid = false;
          } else {
            $(this.nextElementSibling).hide();
          }
        } else if ($(requiredInput).attr("type") === "email") {
          if (!$(requiredInput).val()) {
            $(this.nextElementSibling).show();
            isValid = false;
          } else {
            $(this.nextElementSibling).hide();
          }
        }
      });
      return isValid;
    }
  });

  $(".contactForm3 form").each(function () {
    var index = $(this).find(".recaptcha-element").attr("data-order");
    var valid = false;
    const $form = $(this);
    const $inputName: any = $("#cf_inputName", $form);
    const $inputEmail: any = $("#cf_inputEmail", $form);
    const $inputCompany = $("#cf_inputCompany", $form);
    const $inputCountry: any = $("#cf_inputCountry", $form);
    const $inputPhone: any = $("#cf_inputPhone", $form);
    const $inputMessage: any = $("#cf_exampleFormControlTextarea1", $form);
    const $cbPolicy: any = $("#cf_cbPolicy", $form);
    const $submitButton = $("#cf_submitButton", $form);
    const $inputWebsite: any = $("#cf_website", $form);
    let contactInfo: any;

    const requiredInputs = [
      $inputName[0],
      $inputEmail[0],
      $inputPhone[0],
      $inputCountry[0],
      $inputMessage[0],
      $cbPolicy[0],
    ];
    $.each(requiredInputs, function (i, requiredInput: any) {
      $(requiredInput).blur(function () {
        validateDataRadio([requiredInput]);
      });
    });
    $(this).submit(function (e) {
      e.preventDefault();
      var validation = validateDataRadio(requiredInputs);
      if (validation) {
        valid = true;
        grecaptcha.execute(index);
      } else {
        valid = false;
        grecaptcha.reset(index);
      }
    });
    window["submit" + index] = function (pos, resp) {
      $(".contact__onload--loader").show();
      $(".contactLoading__container").show();
      var data = {};
      $.ajax({
        type: "POST",
        url: "/api/sitecore/Contact/PostContactForm",
        data: {
          token: resp,
          itemId: $form.data("page"),
          name: $inputName.val(),
          email: $inputEmail.val(),
          company: $inputCompany.val(),
          country: $inputCountry.val(),
          phone: $inputPhone.val(),
          message: $inputMessage.val(),
          policy: "1",
          ContactBy: contactInfo,
          Website: $inputWebsite.val()
        },
        success: function (data) {

          if (data.success == true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submission",
              formCategory: "Find us page",
            });

            $(".contact__onload-responseHeadline").text(data.responseHeadline);
            $(".contact__onload-responseMessage").text(data.responseMessage);
            $(".contact__onload--loader").hide();
            $(".contactLoading__container").click(function (e) {
              resetForm();
              $(".contactLoading__container").hide();
            });
          } else {
            $(".contact__onload-responseHeadline").text(data.responseHeadline);
            $(".contact__onload-responseMessage").text(data.responseMessage);
            $(".contact__onload--loader").hide();
            $(".contactLoading__container").click(function (e) {
              resetForm();
              $(".contactLoading__container").hide();
            });
          }
        },
        error: function (e) {

          $(".contact__onload-responseHeadline").text("Error ");
          $(".contact__onload-responseMessage").text("Server error");
          $(".contact__onload--loader").hide();
          $(".contactLoading__container").click(function (e) {
            resetForm();
            $(".contactLoading__container").hide();
          });
        },
      });
    };
    function validateDataRadio(requiredInputs) {
      $<HTMLInputElement>(".cf_contactInfo").each(function () {
        if (this.checked) {
          contactInfo = this.value;
        }
      });
      let isValid = true;
      $.each(requiredInputs, function (i, requiredInput: any) {
        if (
          $(requiredInput).attr("type") === "text" ||
          $(requiredInput).is("textarea")
        ) {
          if (!$(requiredInput).val()) {
            $(this.nextElementSibling).show();
            isValid = false;
          } else {
            $(this.nextElementSibling).hide();
          }
        } else if (
          $(requiredInput).attr("type") === "tel" &&
          contactInfo === "phone"
        ) {
          if (!$(requiredInput).val()) {
            $(this.nextElementSibling).show();
            isValid = false;
          } else {
            $(this.nextElementSibling).hide();
          }
        } else if (
          $(requiredInput).attr("type") === "email" &&
          contactInfo === "email"
        ) {
          var emailReg =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let validEmail: any = $(requiredInput).val();

          if (!emailReg.test(validEmail.toLowerCase())) {
            $(this.nextElementSibling).show();
            isValid = false;
          } else {
            $(this.nextElementSibling).hide();
          }
        } else if ($(requiredInput).attr("type") === "checkbox") {
          if ($(this).is(":checked") === false) {
            $(this.parentNode.parentNode.children[1]).show();
            isValid = false;
          } else {
            $(this.parentNode.parentNode.children[1]).hide();
          }
        }
      });
      return isValid;
    }
    function resetForm() {
      $(".form-group").each(function () {
        $(this).find(".form-control").val("");
        $(this).removeClass("valid");
      });
      $(".policy").prop("checked", false);
    }
  });

  $(".ir-contact-form form").each(function () {
    var index = $(this).find(".recaptcha-element").attr("data-order");
    var valid = false;
    const $form = $(this);
    const $inputName: any = $("#cf_inputName", $form);
    const $inputTitle: any = $("#cf_inputTitle", $form);
    const $inputEmail: any = $("#cf_inputEmail", $form);
    const $inputCompany: any = $("#cf_inputCompany", $form);
    const $inputPhone: any = $("#cf_inputPhone", $form);
    const $inputMessage: any = $("#cf_exampleFormControlTextarea1", $form);
    const $cbPolicy: any = $("#cf_cbPolicy", $form);
    const $submitButton = $("#cf_submitButton", $form);
    const requiredInputs = [
      $inputName[0],
      $inputEmail[0],
      $inputMessage[0],
      $cbPolicy[0],
    ];
    $.each(requiredInputs, function (i, requiredInput: any) {
      $(requiredInput).blur(function () {
        validateData([requiredInput]);
      });
    });

    $(this).submit(function (e) {
      e.preventDefault();
      var validation = validateData(requiredInputs);
      if (validation) {
        valid = true;
        grecaptcha.execute(index);
      } else {
        valid = false;
        grecaptcha.reset(index);
      }
    });
    window["submit" + index] = function (pos, resp) {
      var data = {};
      $.ajax({
        type: "POST",
        url: "/api/sitecore/IRContact/PostIRContactForm",
        data: {
          token: resp,
          itemId: $form.data("item"),
          pageId: $form.data("page"),
          name: $inputName.val(),
          title: $inputTitle.val(),
          email: $inputEmail.val(),
          company: $inputCompany.val(),
          phone: $inputPhone.val(),
          message: $inputMessage.val(),
          policy: "1",
        },
        success: function (data) {
          if (data.success === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submission",
              formCategory: "Investor Relations form",
            });

            $(".contactLoading__container").hide();
            $(".thank-you-message").show();
            $(".thank-you-message__responseHeadline").text(
              data.responseHeadline
            );
            $(".thank-you-message__responseMessage").text(data.responseMessage);
            $(".error-message").hide();
            $form.hide();
            resetForm();
          } else {
            resetForm();
            $(".contactLoading__container").hide();
            $(".error-message").show();
            $(".error-message__responseHeadline").text(data.responseHeadline);
            $(".error-message__responseMessage").text(data.responseMessage);
            $(".thank-you-message").hide();
            $form.hide();
            $(".error-message__button").click(function (e) {
              $form.show();
              $(".error-message").hide();
            });
          }
        },
        error: function (e) {
          $(".contactLoading__container").hide();
          $(".error-message").show();
          $(".error-message__responseHeadline").text("Error");
          $(".error-message__responseMessage").text(
            "Could not connect to server"
          );
          $(".thank-you-message").hide();
          $(".error-message__button").click(function (e) {
            $form.show();
            $(".error-message").hide();
          });
        },
      });
    };
    function resetForm() {
      $(".form-group").each(function () {
        $(this).find(".form-control").val("");
        $(this).removeClass("valid");
      });
      $(".policy").prop("checked", false);
    }
  });

  $(".ir-order-form form").each(function () {
    var index = $(this).find(".recaptcha-element").attr("data-order");

    var valid = false;
    const $form = $(this);
    const $inputName: any = $("#cf_inputName", $form);
    const $inputZipcode: any = $("#cf_inputZipcode", $form);
    const $inputEmail: any = $("#cf_inputEmail", $form);
    const $inputCompany: any = $("#cf_inputCompany", $form);
    const $inputStreet: any = $("#cf_inputStreet", $form);
    const $inputPostalAddress: any = $("#cf_inputPostalAddress", $form);
    const $inputPhone: any = $("#cf_inputPhone", $form);
    const $inputMessage: any = $("#cf_exampleFormControlTextarea1", $form);
    const $cbPolicy = $("#cf_cbPolicy", $form);
    const $submitButton = $("#cf_submitButton", $form);
    let choosenDocuments = [];
    let choosenLanguages = [];
    const requiredInputs = [
      $inputName[0],
      $inputEmail[0],
      $inputMessage[0],
      $cbPolicy[0],
    ];
    $.each(requiredInputs, function (i, requiredInput: any) {
      $(requiredInput).blur(function () {
        validateData([requiredInput]);
      });
    });

    $(this).submit(function (e) {
      e.preventDefault();
      checkSelectedItems();
      var validation = validateData(requiredInputs);
      if (!choosenDocuments.length) {
        validation = false;
        $(".order__error").show();
      }

      if (validation) {
        grecaptcha.execute(index);
      } else {
        valid = false;
        grecaptcha.reset(index);
      }
    });
    window["submit" + index] = function (pos, resp) {
      $(".contactLoading__container").show();

      var data = {};
      $.ajax({
        type: "POST",
        url: "/api/sitecore/IRContact/PostIROrderForm",
        data: {
          token: resp,
          itemId: $form.data("item"),
          pageId: $form.data("page"),
          documents: choosenDocuments,
          languages: choosenLanguages,
          name: $inputName.val(),
          company: $inputCompany.val(),
          phone: $inputPhone.val(),
          email: $inputEmail.val(),
          zipCode: $inputZipcode.val(),
          postalAddress: $inputPostalAddress.val(),
          street: $inputStreet.val(),
          message: $inputMessage.val(),
          policy: "1",
        },
        success: function (data) {
          if (data.success === true) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "form_submission",
              formCategory: "Order printed publications",
            });

            $(".contactLoading__container").hide();
            $(".thank-you-message").show();
            $(".thank-you-message__responseHeadline").text(
              data.responseHeadline
            );
            $(".thank-you-message__responseMessage").text(data.responseMessage);
            $(".error-message").hide();
            $form.hide();
            resetForm();
          } else {
            resetForm();
            $(".contactLoading__container").hide();
            $(".error-message").show();
            $(".error-message__responseHeadline").text(data.responseHeadline);
            $(".error-message__responseMessage").text(data.responseMessage);
            $(".thank-you-message").hide();
            $form.hide();
            $(".error-message__button").click(function (e) {
              $form.show();
              $(".error-message").hide();
            });
          }
        },
        error: function (e) {
          $(".contactLoading__container").hide();
          $(".error-message").show();
          $(".error-message__responseHeadline").text("Error");
          $(".error-message__responseMessage").text(
            "Could not connect to server"
          );
          $(".thank-you-message").hide();
          $(".error-message__button").click(function (e) {
            $form.show();
            $(".error-message").hide();
          });
        },
      });
    };
    function resetForm() {
      $(".form-group").each(function () {
        $(this).find(".form-control").val("");
        $(this).removeClass("valid");
      });
      $(".policy").prop("checked", false);
      choosenDocuments = [];
      choosenLanguages = [];
    }

    function checkSelectedItems() {
      $(".ir-order-form .order-publications__documents").each(function () {
        const $documents: any = $(this);
        if ($documents[0].checked) {
          choosenDocuments.push($documents[0].value);
        }
      });
      $(".ir-order-form .order-publications__languages").each(function () {
        const $languages: any = $(this);
        if ($languages[0].checked) {
          choosenLanguages.push($languages[0].value);
        }
      });
    }
  });

  function validateData(requiredInputs) {
    let isValid = true;
    $.each(requiredInputs, function (i, requiredInput: any) {
      if (
        $(requiredInput).attr("type") === "text" ||
        $(requiredInput).is("textarea")
      ) {
        if (!$(requiredInput).val()) {
          $(this.nextElementSibling).show();
          isValid = false;
        } else {
          $(this.nextElementSibling).hide();
        }
      } else if ($(requiredInput).attr("type") === "tel") {
        if (!$(requiredInput).val()) {
          $(this.nextElementSibling).show();
          isValid = false;
        } else {
          $(this.nextElementSibling).hide();
        }
      } else if ($(requiredInput).attr("type") === "email") {
        var emailReg =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validEmail: any = $(requiredInput).val();
        if (!emailReg.test(validEmail.toLowerCase())) {
          $(this.nextElementSibling).show();
          isValid = false;
        } else {
          $(this.nextElementSibling).hide();
        }
      } else if ($(requiredInput).attr("type") === "checkbox") {
        if ($(this).is(":checked") === false) {
          $(this.parentNode.parentNode.children[1]).show();
          isValid = false;
        } else {
          $(this.parentNode.parentNode.children[1]).hide();
        }
      }
    });
    return isValid;
  }
});

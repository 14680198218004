import { ForEach } from "../helpers/dom";
let selectContainer = document.querySelectorAll<HTMLElement>(
  ".pdf-selected__container"
);
if (selectContainer) {
  let image = document.querySelectorAll<HTMLImageElement>(".pdf-selected__image") ;
  let pdfContainer = document.querySelectorAll<any>(".pdf-selected__image__link");
  let description = document.querySelectorAll(".pdf-selected__description");

  ForEach(selectContainer, function (select: HTMLElement, idx) {
    select.onchange = function (e:any) {
      let data = e.target.options[e.target.selectedIndex];
      description[idx].children[0].textContent = data.dataset.name;
      image[idx].src = data.dataset.thumbnail;
      pdfContainer[idx].dataset.publication = data.dataset.publication;
    };
  });
}

import Helper from "./helper";
import { ForEach, getData } from "../helpers/dom";
import { renderAllPositions } from "./renderAllPositions";
import { renderAllServices } from "./renderAllServices";
import { renderAllEvents } from "./renderAllEvents";
const selectWrapper = document.querySelector<HTMLElement>(".filter__wrapper");
const dropdownSelection = document.querySelector<HTMLDivElement>(
  ".filter__all-container"
);
let selectValues = document.querySelectorAll<HTMLElement>(
  ".filter__select-selector-choosed--text"
);
const filterCases = document.querySelector<HTMLElement>(".filter__cases");
const filterJobs = document.querySelector<HTMLElement>(".filter__jobs");
const filterServices = document.querySelector<HTMLElement>(".filter__services");
const filterStories = document.querySelector<HTMLElement>(".filter__stories");
const filterEvents = document.querySelector<HTMLElement>(".filter__events");
const filterCompanies = document.querySelector<HTMLElement>(
  ".filter__companies"
);
const employeeStories = document.querySelector<HTMLElement>(
  ".filter__employeeStories"
);

let searchValues = [];
let selectionValues = [
  { CountryTags: [] },
  { CompanyTags: [] },
  { IndustryTags: [] },
  { OtherTags: [] },
  { ServiceTags: [] },
];
let URLSearch = window.location.search;

let filterURL: string;
let dropdown: HTMLElement;
if (selectWrapper) {
  if (filterCases) {
    filterURL = "/Article/GetCases";
  } else if (filterJobs) {
    filterURL = "/Job/GetJobs";
  } else if (filterServices) {
    let data =  $(filterServices).attr('data-index');
    filterURL = `/Article/GetServices?curIndex=${data}`;    
  } else if (filterStories) {
    filterURL = "/Article/GetStories";
  } else if (filterEvents) {
    filterURL = "/Article/GetAllEvents";
  } else if (filterCompanies) {
    filterURL = "/Article/GetCompanies";
  } else if (employeeStories) {
    filterURL = "Article/GetEmploeeStories";
  }
  console.log('filterURL',filterURL);
  getData(filterURL).then((data) => {
    if (
      filterCases ||
      filterStories ||
      filterCompanies ||
      filterServices ||
      employeeStories
    ) {
      renderAllServices(data);
    } else if (filterJobs) {
      renderAllPositions(data);
    } else if (filterEvents) {
      renderAllEvents(data);
    }
    if (!data.length) return;

    for (let i = 0; i < selectionValues.length; i++) {
      let val = selectionValues[i];
      let key = Object.keys(val);
      for (let j = 0; j < data.length; j++) {
        if (data[j][key[0]] && data[j][key[0]][0]) {
          let keyData = data[j][key[0]];
          for (let searchvalue of keyData) {
            val[key[0]].push(searchvalue);
          }
        }
      }
      let removeDuplicate = [];
      $.each(val[key[0]], function (i, el) {
        if ($.inArray(el, removeDuplicate) === -1) removeDuplicate.push(el);
      });
      if (removeDuplicate.length) {
        val[key[0]] = removeDuplicate;
      }
      val[key[0]] = removeDuplicate;
      removeDuplicate.sort();
    }
    for (let i = selectionValues.length - 1; i >= 0; i--) {
      let val = selectionValues[i];
      let key = Object.keys(val);
      if (val[key[0]].length < 1) {
        selectionValues.splice(i, 1);
      }
    }
    let selectionTags = selectionValues;

    let template = "";
    let template2 = "";

    for (let item of selectionTags) {
      let key: any = Object.keys(item);
      let attributeKey = key[0].toLowerCase();
      let title = selectWrapper.getAttribute("data-" + attributeKey);
      for (let drop of item[key]) {
        template2 += `
                        <div class="filter__select-selector-dropdown--container">
                            <div class="nolato-checkbox">
                                <label class="nolato-checkbox__title order-publications__item-text">
                                    ${drop}
                                    <input class="filterCheckbox" data-filter=${key} value="${drop}" type="checkbox">
                                    <span class="nolato-checkbox__checkmark"></span>
                                </label>
                            </div>
                        </div>
                    `;
      }
      template += `
                    <div class="col-lg-4 col-md-6 filter__container">
                        <div class="filter__select">
                            <div class="filter__select-text">
                                <span class="nolato__uppercase-red-text">filter by ${title}</span>
                            </div>
                            <div class="filter__select-selector">
                                <div class="filter__select-selector-choosed">
                                    <span class="filter__select-selector-choosed--text">Select</span>
                                    <span class="filter__select-selector-choosed--icon"></span>
                                </div>
                                <div class="filter__select-selector-dropdown">
                                            ${template2}
                                    </div>
                                </div>
                        </div>
                    </div>
                `;
      template2 = "";
      dropdownSelection.insertAdjacentHTML("beforeend", template);
      template = "";
    }
    let overlay = Helper.overlay;
    const selectContainer = document.querySelectorAll<HTMLElement>(
      ".filter__select"
    );
    let dropDowns = document.querySelectorAll<HTMLElement>(
      ".filter__select-selector-dropdown"
    );
    ForEach(selectContainer, function (element: HTMLElement, idx) {
      let selectButton = element.children[1];
      selectButton.addEventListener("click", function () {
        dropdown = dropDowns[idx];
        dropdown.classList.add("nolato__active");
        overlay.classList.add("nolato__active");
        overlay.addEventListener("click", function () {
          dropdown.classList.remove("nolato__active");
          overlay.classList.remove("nolato__active");
        });
      });
    });

    let checkboxes = document.querySelectorAll(".filterCheckbox");
    ForEach(checkboxes, function (checkbox: HTMLInputElement) {
      if (URLSearch) {
        let SplitURLSearch = URLSearch.slice(1)
          .split("&")[0]
          .split("=")[1]
          .toLowerCase()
          .replaceAll('-', ' ')
          .replaceAll('%20', ' ');
        let checkboxValue = checkbox.value.toLowerCase();
        if (SplitURLSearch.includes(checkboxValue)) {
          let searchVal = addCheckboxValueToSearchValues(checkbox);
          searchValues.push(searchVal);
          checkbox.checked = true;
          filterFunction(data);
        }
      }
      $(checkbox).change(function (e) {
        let checkbox = e.target;
        if (this.checked === true) {
          let searchVal = addCheckboxValueToSearchValues(checkbox);
          searchValues.push(searchVal);
        } else {
          let index = searchValues.findIndex(
            (i) => i[checkbox.dataset.filter] === checkbox.value
          );
          if (index !== -1) {
            searchValues.splice(index, 1);
          }
        }
        filterFunction(data);
      });
    });
    function addCheckboxValueToSearchValues(checkbox) {
      let key = checkbox.dataset.filter;
      let val = checkbox.value;
      let searchVal = {};
      searchVal[key] = val;
      searchVal[key] = val;
      return searchVal;
    }
    function filterFunction(data) {
      let filteredData = data;
      let newData = [];
      for (let searchValue of searchValues) {
        filteredData = filterByValue(searchValue, data);
        newData = [...newData, ...filteredData];
        filteredData = newData;
      }
      filteredData = removeDublicate(filteredData);
      if (filterJobs) {
        renderAllPositions(filteredData);
      } else if (
        filterCases ||
        filterServices ||
        filterStories ||
        filterCompanies ||
        employeeStories
      ) {
        renderAllServices(filteredData);
      } else if (filterEvents) {
        renderAllEvents(filteredData);
      }
    }
    function filterByValue(searchValue, data) {
      return data.filter((obj) =>
        Object.entries(searchValue).every(([prop, find]) =>
          obj[prop].includes(find)
        )
      );
    }

    function removeDublicate(data) {
      let removedItems;
      let removeDuplicate = [];
      $.each(data, function (i, el) {
        if ($.inArray(el, removeDuplicate) === -1) removeDuplicate.push(el);
      });
      removeDuplicate.sort();
      removedItems = removeDuplicate;
      return removedItems;
    }
  });
}

import {ForEach,SlideDown, SlideUp} from "../helpers/dom";
    const faqContainers = document.querySelectorAll(".FAQ__collapse-item");
    if(faqContainers && faqContainers.length){
        const icons = document.querySelectorAll(".FAQ__collapse-button");
        let index = null;
    ForEach(faqContainers, function(container, idx){
        container.addEventListener("click",function(){
            let collapseContainer = <HTMLDivElement>document.querySelectorAll(".FAQ__collapse-container")[idx]
            SlideDown(collapseContainer,300)
            if(index === idx){
                removeClasses(index);
                index = null;
                return;
            }
            if(index || index === 0){
                removeClasses(index)
            }
            index = idx;
            icons[index].classList.add("FAQ__collapse-button--active");
        })
        function removeClasses(index){
            let collapseContainer = <HTMLDivElement>document.querySelectorAll(".FAQ__collapse-container")[index]
            SlideUp(collapseContainer,300)
            icons[index].classList.remove("FAQ__collapse-button--active");
        }
    });
}
  
import { ForEach } from "../helpers/dom";
const cardCollapseSection = document.querySelectorAll<HTMLElement>(
  ".cardCollapseSection"
);
if (cardCollapseSection.length) {
  ForEach(cardCollapseSection, function (section: HTMLElement, idx) {
    
    const collapsecontainer = document.querySelectorAll<HTMLElement>(
      ".collapsebutton"
    )[idx];
    const cardSectionShowAllButton = document.querySelectorAll<HTMLElement>(
      ".collapsebutton-section"
    )[idx];
    const buttonText = document.querySelectorAll<HTMLElement>(
      ".collapsebutton-text"
    )[idx];
    const whiteOverlay = document.querySelectorAll<HTMLElement>(
      ".cardsection__overlay"
    )[idx];
    
    let cards = cardCollapseSection[idx].children[0].children[2].children;
    buttonText.textContent = collapsecontainer.dataset.expand ;
    let amountOfCards = parseInt(section.dataset.maximumcards);
      collapsecontainer.style.display = "flex";
      if ($(window).width() <= 575) {
        amountOfCards = 3;
      }
      cardAppearance(cards, amountOfCards);
    
    let toggle = false;
    cardSectionShowAllButton.addEventListener("click", function () {
      if (!toggle) {
        let collapse = collapsecontainer.dataset.collapse
        buttonText.textContent = collapse;
        buttonText.classList.add("collapsebutton--close");
        cardAppearance(cards, cards.length + 1);
      } else {
        let expand = collapsecontainer.dataset.expand
        buttonText.textContent = expand;
        buttonText.classList.remove("collapsebutton--close");
        cardAppearance(cards, amountOfCards);
      }
      toggle = !toggle;
    });

    function cardAppearance(data, amount) {
      if(amountOfCards >= cards.length || !amountOfCards){
        collapsecontainer.style.display = "none"
      }
      ForEach(data, function (card: HTMLElement, idx) {
        if (idx >= amount) {
          card.style.display = "none";
          whiteOverlay.style.height = "230px";
        } else {
          card.style.display = "block";
          whiteOverlay.style.height = "0px";
        }
      });
    }
  });
}

import {LazyLoad} from "./intersectionObserver";
const allServicesContainer = document.querySelector<any>(".all__cards");
export const renderAllServices = (data) => {
    let filteredData = data
    let template = "";
    allServicesContainer.innerHTML = ""
    if (filteredData.length) {
        for (let service of filteredData) {
            template += `
                <div class="col-lg-4 col-md-6 imagecard__col"  style="visibility:hidden">
                <div class="imagecard__wrapper">
                    <div class="imagecard__image-container">
                        <img data-link="${service.ListingImageUrl}" src="" alt='${service.ListingImageAlt ? service.ListingImageAlt : ""}'>
                    </div>
                    <div class="imagecard__container">
                        <div class="imagecard__title">
                        ${service.OptionalHeading ? `<p class="imagecard__date">${service.OptionalHeading}</p>` : ""}
                            <h4 class="imagecard__title-header">${service.Heading ? service.Heading : ""}</h4>
                        </div>
                        <div class="imagecard__text">
                            <p>
                                ${service.Preamble ? service.Preamble : ""}
                            </p>
                        </div>
                        <div class="imagecard__button">
                            <a class="text-with-red-arrow-right link__hover" href='${service.ServiceUrl ? service.ServiceUrl : ""}'>
                            ${service.LinkText ? service.LinkText : ""}
                            </a>
                        </div>
                    </div>
                    </div>
                </div>`;
        }
        allServicesContainer.insertAdjacentHTML('beforeend', template);
        const items = document.querySelectorAll(".imagecard__col");
        LazyLoad(items);
    } else {
        let noResult = allServicesContainer.dataset.noresulttext;
        template = `
            <div class="col-lg-12">
               ${noResult}
            </div>
            `
        allServicesContainer.insertAdjacentHTML('beforeend', template);
    }
}


let hotSpots = <HTMLDivElement>document.getElementById("hotSpots");
import { getData, ForEach } from "../helpers/dom";
const hotspotWrapper = document.querySelector(".hotSpots__wrapper");
const hotSpotSubTitle = document.querySelector(".hotSpots__header--subTitle");
const hotSpotTitle = document.querySelector(".hotSpots__header--title");
const hotSpotDescription = document.querySelector(".hotSpots__header--desciption");
let sliderItems: any;
let openModalToggle: any;
let sliderModalIndex = 0;

let index = 0;
if (hotSpots) {
    let data =  $('#hotSpots').attr('data-ID');
    getData(`/MainComponents/GetHotSpots?ID=${data}`)
        .then(hotSpotsData => {
            const leftButton = document.querySelector<HTMLDivElement>(".hotSpots__leftArrow");
            const rightButton = document.querySelector<HTMLDivElement>(".hotSpots__rightArrow");
            renderSliderItems();
            setImageHotspots();

            leftButton.addEventListener("click", function () {
                getIndex(index - 1)
            })

            rightButton.addEventListener("click", function () {
                getIndex(index + 1)
            })

            function renderSliderItems() {
                const sliderContainer = <HTMLDivElement>document.querySelector(".hotSpots__slider");
                let template = "";
                for (let image of hotSpotsData) {
                    template += `
                        <div class="hotSpots__slider--wrapper">
                            <img class="hotSpots__slider--image" src="${image.imageLink}" alt="" width="233" height="165">
                        </div>
                        `
                }
                sliderContainer.insertAdjacentHTML('beforeend', template);
                sliderItems = document.querySelectorAll<HTMLDivElement>(".hotSpots__slider--wrapper");
                ForEach(sliderItems, function (sliderItem: HTMLElement, idx) {
                    sliderItem.addEventListener("click", function () {
                        changePicture(idx);
                    })
                })
            }

            function setImageHotspots() {
                let hotSpotsChoosenImage = hotSpotsData[index].imageLink;
                let hotspots = hotSpotsData[index].hotspots
                renderHotSpots(hotspots, hotSpotsChoosenImage);
                sliderItems[index].classList.add("hotSpots__slider--active")
                hotSpotSubTitle.textContent = hotSpotsData[index].subTitle;
                hotSpotTitle.textContent = hotSpotsData[index].title;
                hotSpotDescription.textContent = hotSpotsData[index].description;
            }

            function changePicture(idx: number) {
                if (index === idx) return;
                sliderItems[index].classList.remove("hotSpots__slider--active")
                index = idx;
                setImageHotspots()
            }

            function getIndex(idx: number) {
                let change = idx
                if (idx > hotSpotsData.length - 1) {
                    change = 0;
                } else if (idx < 0) {
                    change = hotSpotsData.length - 1;
                }
                changePicture(change);
            }

            function renderHotSpots(hotspots, hotSpotsChoosenImage) {
                hotspotWrapper.innerHTML = "";
                let hotspotsTemplate = `<img class="hotSpots__wrapper--image" src="${hotSpotsChoosenImage}" alt="Image" width="980" height="690">`;
                for (let hotspot of hotspots) {
                    hotspotsTemplate += `
                        <div class="hotSpots__button" style="top:${hotspot.top}%; left:${hotspot.left}%">
                        </div>
                    `;
                }
                hotspotWrapper.insertAdjacentHTML('beforeend', hotspotsTemplate);
                let hotspotsButtons = document.querySelectorAll(".hotSpots__button");

                ForEach(hotspotsButtons, function (hotspotsButton: HTMLElement, idx) {
                    hotspotsButton.addEventListener("click", function (el) {
                        if (hotspotsButton !== el.target) return;
                        let modal = hotspots[idx];
                        openHotspotsModal(modal.description, hotspotsButton);
                    })
                })
            }

            function openHotspotsModal(data, openModal) {
                if(!data.length)return;
                if (openModalToggle) {
                    closeHotspotsModal(openModalToggle);
                }
                if (openModal === openModalToggle) {
                    closeHotspotsModal(openModalToggle)
                    openModalToggle = "";
                    return;
                }
                openModalToggle = openModal;
                rendermodal(data, openModalToggle, sliderModalIndex)
            }

            function rendermodal(data, modal, sliderModalIndex) {
                modal.innerHTML = ""
                let modalSlideTemplate = "";
                if (data.length >= 2) {
                    let indexItem = 1;
                for (let slide of data) {
                    modalSlideTemplate += `
                        <li class="hotSpots__modal--item">${indexItem}</li>
                    `
                    indexItem ++;
                }
                }
                let modalTemplate = `
                    <div class="hotSpots__modal">
                        <div class="hotSpots__modal--container">
                            <div class="hotSpots__close">
                                <div class="hotSpots__close--1">
                                    <div class="hotSpots__close--2"></div>
                                </div>
                            </div>
                            <h3 class="hotSpots__modal--title">${data[sliderModalIndex].title}</h3>
                            <p class="hotSpots__modal--description">
                            ${data[sliderModalIndex].description}
                            </p>
                        </div>
                        <div class="hotSpots__modal--bottom">
                            <a class ="text-with-red-arrow-right link__hover" href="${data[sliderModalIndex].link}">${data[sliderModalIndex].linkText}</a>
                        </div>
                        <ul class="hotSpots__modal--items">
                        ${modalSlideTemplate}
                        </ul>
                    </div>`;
                modal.insertAdjacentHTML('beforeend', modalTemplate);
                let modalSliderButtons = document.querySelectorAll(".hotSpots__modal--item");
                ForEach(modalSliderButtons, function (modalSliderButton: HTMLElement, idx) {
                    if (sliderModalIndex === idx) {
                        modalSliderButton.style.color = "#D1291C"
                    } else {
                        modalSliderButton.style.color = "#e8e8e8"
                    }
                    modalSliderButton.addEventListener("click", function (e) {
                        if (modalSliderButton !== e.target) return;
                        sliderModalIndex = idx;
                        rendermodal(data, modal, sliderModalIndex)
                    })
                })
                let closeModalButton = document.querySelector(".hotSpots__close");
                closeModalButton.addEventListener("click", function () {
                    closeHotspotsModal(openModalToggle);
                    openModalToggle = "";
                })
            }
            function closeHotspotsModal(openModalToggle) {
                openModalToggle.innerHTML = "";

            }
        })
}
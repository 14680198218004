import { format } from "path";

const thickerToolbar = <HTMLDivElement>document.getElementById("ticker-holder");
const tickerComponent = document.getElementById("tickerComponent");
const axios = require("axios").default;
if (thickerToolbar) {
  let URL = thickerToolbar.dataset.url;
  getTicker(URL).then((res: object) => {
    buildTicker(res);
  });
}
if (tickerComponent) {
  let URL = tickerComponent.dataset.url;
  getTicker(URL).then((res: object) => {
    buildTickerComponent(res);
  });
}

function buildTicker(data) {
  let deltaToday = true;
  if (data.deltaTodaySek < 0) {
    deltaToday = false;
  }

  // let template = `    
  //       <span>${data.stockSymbol}</span>
  //       <span><span class="toolbar__thicker--today ${
  //         deltaToday ? "increase" : "decrease"
  //       }">${data.deltaTodaySek.toFixed(2)}</span> SEK</span>
  //       <span>${data.latestPrice.toFixed(2)}</span>
  //   `;

  let deltaProcent = data.deltaToday * 100;
  let template = `    
        <span class=${deltaToday ? "increase" : "decrease"}>${data.stockSymbol} &nbsp;|&nbsp; ${deltaProcent.toFixed(2)} % &nbsp;|&nbsp; ${data.latestPrice.toFixed(2)} SEK</span>`;

  thickerToolbar.insertAdjacentHTML("beforeend", template);
}

function buildTickerComponent(data) {
  let latestupdate = tickerComponent.dataset.latestupdate;
  let shareprice = tickerComponent.dataset.shareprice;
  let quotesaredelayed = tickerComponent.dataset.quotesaredelayed;
  let deltaToday = true;
  if (data.deltaTodaySek < 0) {
    deltaToday = false;
  }
  if (shareprice === undefined || shareprice.length==0){
    if(document.documentElement.lang=="sv-SE"){
      shareprice = "Aktiekurs";
    }
    else{
      shareprice = "Share price";
      
    }
    
  }

  function getPercent(value) {
    return (value * 100).toFixed(2);
  }
  function formatTime(time) {
    let split = time.split(":").slice(0, -1);
    //let GMT = parseInt(split[0]) + 2;
    //let GMT = parseInt(split[0]) + 1;
    let GMT = parseInt(split[0]);
    if (split[0].length === 1) {
      split[0] = "0" + GMT;
    } else {
      split[0] = GMT;
    }

    let timeToString = split.map(String);
    let formatString = timeToString.toString();
    let replace = formatString.replace(",", ":");
    return replace;
  }

  let template2 = `
    <div class="tickerComponent__col-1">
        <div class="tickerComponent__stockSymbols">
          <h4 class="tickerComponent__stockSymbols--title">${shareprice}</h4>
          <h3 class="tickerComponent__stockSymbols--stock">Nolato b (${
            data.stockSymbol
          })</h3>
        </div>
        <div class="tickerComponent__latestPrice">
          <h2 class="tickerComponent__latestPrice--title">${data.latestPrice.toFixed(
            2
          )}</h2>
        </div>
        <div class="tickerComponent__deltatoday">
        <p class="tickerComponent__deltatoday--delta">
        ${getPercent(data.deltaToday)} %</p>
          <span class="tickerComponent__deltatoday--icon ${
            deltaToday ? "" : "tickerComponent__deltatoday--decrease"
          }"></span>
          <p class="tickerComponent__deltatoday--delta">${data.deltaTodaySek.toFixed(
            2
          )} SEK</p>
        </div>
      </div>
      <div class="tickerComponent__col-2">
          <div class="tickerComponent__info">
            <p class="tickerComponent__info--text">
               ${latestupdate}: ${data.latestDate} ${formatTime(
    data.latestTime
  )}
            </p>
            <p class="tickerComponent__info--text">
                ${quotesaredelayed}
            </p>

      </div>
    </div>

  `;
  tickerComponent.insertAdjacentHTML("beforeend", template2);
}

function getTicker(URL: string) {
  return axios
    .get(URL)
    .then((response) => {
      return response.data.stocks[0];
    })
    .catch((error) => {
      console.log(error, "error response");
    });
}

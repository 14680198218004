import {LazyLoadEvents} from "./intersectionObserver";
const allServicesContainer = document.querySelector<any>(".all__events");
export const renderAllEvents = (events) => {
    let filteredData = events
    allServicesContainer.innerHTML = "";
    let template = "";
    if (filteredData.length >= 1) {
        for (let event of filteredData) {
            template += `
                <div class="logo-card__wrapper"  style="visibility:hidden">
                    <a href='${event.Urllink ? event.Urllink : ""}'>
                        <div class="logo-card__header">
                            <div class="logo-card__header-logo">
                            <img data-link='${event.EventImageUrl ? event.EventImageUrl : ""}' src="" alt='${event.EventImageAlt ? event.EventImageAlt : ""}'>
                            </div>
                            <div class="logo-card__header-date">
                                <span>${event.EventDate ? event.EventDate : ""} <br>${event.EventEndDate ? event.EventEndDate : ""}</span>
                            </div>
                        </div>
                        <div class="logo-card__body">
                            <div class="logo-card__body-name">
                                <h2>
                                    ${event.EventName ? event.EventName : ""}
                                </h2>
                            </div>
                            <div class="logo-card__body-info">
                                <p>
                                ${event.EventLocation ? event.EventLocation : ""}
                                </p>
                                <span class="text-with-red-arrow-right nolato__uppercase-red-text link__hover">
                                    ${event.ListingLinkText ? event.ListingLinkText : ""}
                                </span>
                            </div>
                        </div>
                    </a>
                </div>
               `;
        }
        allServicesContainer.insertAdjacentHTML('beforeend', template);
        const items = document.querySelectorAll(".logo-card__wrapper");
        LazyLoadEvents(items);
    } else {
        let noResult = allServicesContainer.dataset.noresulttext;
        template = `
            <div class="col-lg-12">
               ${noResult}
            </div>
            `
        allServicesContainer.insertAdjacentHTML('beforeend', template);
    }
}

import { ContactModal,iframeFlyout, ForEach, SlideDown, SlideUp } from "../helpers/dom";
const toolbarContainer = document.querySelector<HTMLElement>(".toolbar");
const secondaryMenu = document.querySelector<HTMLElement>(".secondary-menu");
const headerTag = document.querySelector<HTMLElement>("nav");
//const navbarLogoWrapper = document.querySelector<HTMLElement>(".nav__logo-wrapper");
//const navbarLogo = document.querySelector<HTMLElement>(".nav__logo-image");
const heroButton = <HTMLAnchorElement>document.querySelector(".hero__button");
const menuLinks = document.querySelector(".nolatoNavigation__toolbar-items");
const dropdownShrink = document.querySelectorAll<HTMLElement>(".nav__navbar-dropdown");
const languageContainer = document.querySelector<HTMLElement>(".choose-language");
let secondaryMenuToggle = true;
let navbarToggle = true;
let heroToggle = true;
let secondaryMenuPosition;
let prevScrollpos = 0;
if (secondaryMenu) {
  secondaryMenuPosition = secondaryMenu.offsetTop - secondaryMenu.offsetHeight - 60;
}

let doitScroll;
window.addEventListener('resize', function () {
  clearTimeout(doitScroll);
  doitScroll = setTimeout(updateComponents, 100);
});
updateComponents();
window.addEventListener("scroll", function () {
  updateComponents();
});

function updateComponents() {
  //let currentScrollPos = window.pageYOffset;
  // if (prevScrollpos < currentScrollPos) {
  //   if (navbarToggle) {
  //     ForEach(dropdownShrink, function (dropdown: HTMLElement, idx) {
  //       dropdown.style.top = "36px"
  //     })
  //     SlideUp(languageContainer, 500);
  //     navbarLogo.classList.add("nav__logo-image--shrink");
  //     if(navbarLogoWrapper.classList.contains('avenue')) {
       
  //       $(navbarLogoWrapper).animate({
  //         'padding-top': 10,
  //         'padding-right': 0,
  //         'padding-bottom': 10,
  //         'padding-left': 0,
  //       }, 500);
  //     }
  //     else {
  //       $(navbarLogoWrapper).animate({
  //         'padding-top': 0,
  //         'padding-right': 0,
  //         'padding-bottom': 0,
  //         'padding-left': 0,
  //       }, 500);
  //     }
  //     headerTag.style.borderBottom = "1px solid #EDE9E8";
  //     navbarToggle = false;
  //   }

  // } else {
  //   if (!navbarToggle) {
  //     ForEach(dropdownShrink, function (dropdown: HTMLElement, idx) {
  //       dropdown.style.top = "45"
  //     })
  //     SlideDown(languageContainer, 500);
  //     navbarLogo.classList.remove("nav__logo-image--shrink");
  //     if(navbarLogoWrapper.classList.contains('avenue')){
  //       navbarLogoWrapper.style.padding = "20px 15px"
  //       $(navbarLogoWrapper).animate({
  //         'padding-top': 20,
  //         'padding-right': 15,
  //         'padding-bottom': 20,
  //         'padding-left': 15,
  //       }, 500);
  //     }
  //     else{
  //       navbarLogoWrapper.style.padding = "10px 15px"
  //       $(navbarLogoWrapper).animate({
  //         'padding-top': 10,
  //         'padding-right': 15,
  //         'padding-bottom': 10,
  //         'padding-left': 15,
  //       }, 500);
  //     }
      

  //     headerTag.style.borderBottom = "none";
  //     navbarToggle = true;
  //   }
  // }
  if (heroButton) {

    let heroButtonyMenuPosition = heroButton.getBoundingClientRect().top - heroButton.offsetHeight + 750;

    if (check(heroButtonyMenuPosition)) {
      if (heroToggle) {
        let liTag = document.createElement("li");
        liTag.classList.add("menubutton__hero");
        if (heroButton.classList.contains("onclick__contact-modal")) {
          liTag.classList.add("onclick__contact-modal");
        }else if(heroButton.classList.contains("onclick__iframe-modal")){
          liTag.classList.add("onclick__iframe-modal");
        }
        menuLinks.appendChild(liTag);
        let aTag = <HTMLAnchorElement>document.createElement("a");
        aTag.textContent = heroButton.textContent;
        liTag.appendChild(aTag);
        if (heroButton.classList.contains("onclick__contact-modal")) {
          liTag.onclick = function (e) {
            e.preventDefault();
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
            'formLocation': 'header',
            'event': 'flyout_contact_form'
            });
            ContactModal();
          };
        }else if(heroButton.classList.contains("onclick__iframe-modal")){
          liTag.onclick = function (e) {
            e.preventDefault();
            iframeFlyout();
          };
        }
        else if (heroButton.href) {
          aTag.href = heroButton.href;
        }
        heroToggle = false;
      }
    }
    else {
      let menuButton = document.querySelector(".menubutton__hero");
      if (menuButton) {
        menuButton.parentNode.removeChild(menuButton);
      }
      heroToggle = true;;
    }
  }

  if (secondaryMenu) {
    if (check(secondaryMenuPosition)) {
      if (secondaryMenuToggle) {
        secondaryMenu.classList.add("secondary-menu__fixed");
        secondaryMenu.style.boxShadow = "0 13px 17px 0 rgba(0,0,0,.1)"
        headerTag.style.boxShadow = "0 13px 17px 0 rgba(0,0,0,.1)"
        secondaryMenuToggle = false;
      }
    } else {
      secondaryMenu.classList.remove("secondary-menu__fixed");
      secondaryMenu.style.boxShadow = ""
      headerTag.style.boxShadow = ""
      secondaryMenuToggle = true;

    }
  }
}

function check(value) {
  if (window.scrollY >= value) {
    return true;
  } else {
    return false;
  }
}
